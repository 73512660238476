import env from '../config/env';

type GoogleStaticMapType = 'hybrid' | 'roadmap' | 'satellite' | 'terrain';
export const getStaticMapImage = (
  coordinates: string,
  size: string,
  mapType: GoogleStaticMapType = 'hybrid',
  zoom = 17
) =>
  `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=${size}&maptype=${mapType}&markers=color:green|label:|icon:${env.GOOGLE_MAPS_ICON}|${coordinates}&key=${env.GOOGLE_API_KEY}`;
