import env from '../../config/env';

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The base URL of the api.
   */
  baseURL?: string;

  /**
   * Milliseconds before we timeout the request.
   */
  timeout?: number;

  useDefaultHeaders?: boolean;
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: env.API_HOST as string,
  // timeout: 31000,
  useDefaultHeaders: true,
};
