import config from '../config';
import * as dateUtil from './date-util';

export const createPath = (...args: string[]) =>
  args.join('/').replace(/([^:]\/)\/+/g, '$1');

export const displayDay = (
  date: Date,
  format = config.dateFormats.weekDayMonthDay,
  literal = false
) => {
  let display;
  if (dateUtil.isToday(date)) {
    display = 'Today';
  } else if (dateUtil.isTomorrow(date)) {
    display = 'Tomorrow';
  } else {
    display = literal ? format : dateUtil.format(date, format);
  }

  return display;
};

export const pluralize = (
  text: string,
  count: number,
  postfix: 'ies' | 's' = 's'
) => {
  return count === 0 || count > 1 ? `${text}${postfix}` : text;
};

export const replace = (
  text: string,
  mapping: { [key: string]: string },
  flags: 'g' | 'gi' | 'i' = 'i'
) => {
  const regex = new RegExp(Object.keys(mapping).join('|'), flags);
  return text.replace(regex, function (matched) {
    return mapping[matched];
  });
};

export const replaceAll = (
  text: string,
  mapping: { [key: string]: string }
) => {
  return replace(text, mapping, 'gi');
};

export const replaceBr = (str: string) => str.replace(/<br \/>/, '\n');
