import React from 'react';
import {
  Icon,
  Image,
  Link,
  Modal,
  Tag,
  Text,
} from '@goodfynd/react-web.lib.ui';
import { AccordionDetails, AccordionSummary } from '@mui/material';

import config from '../../../config';
import strings from '../../../config/strings';
import { dateUtil, mapsUtil } from '../../../utils';

import {
  StyledAccordion,
  StyledMapContainer,
  StyledModalContent,
} from './styles';
import type { VendorScheduleProps } from './types';

const VendorSchedule = ({ events, vendor, ...props }: VendorScheduleProps) => {
  const [expanded, setExpanded] = React.useState('');

  const renderContent = () => {
    const handleChange =
      (entry: OperatingDayLocation) =>
      (_e: React.SyntheticEvent, isExpanded: boolean) =>
        setExpanded(isExpanded ? entry.id : '');

    return (
      <>
        <StyledModalContent>
          {events.map((entry) => {
            const isExpanded = expanded === entry.id;
            return (
              <StyledAccordion
                expanded={isExpanded}
                key={entry.id}
                onChange={handleChange(entry)}
              >
                <AccordionSummary
                  aria-controls={`${entry.location.name || entry.id}-content`}
                  expandIcon={<Icon name="chevron_down" />}
                  id={entry.id}
                >
                  <Text>{entry.location.name || entry.location.address}</Text>

                  <Text as="body2">
                    <span>
                      {dateUtil.format(
                        new Date(entry.start as string),
                        config.dateFormats.weekDayMonthDay
                      )}
                      {config.unicode.centerDotPadded}
                      {dateUtil.getTimeDisplay(
                        new Date(entry.start as string),
                        true,
                        true
                      )}
                      &nbsp;-&nbsp;
                      {dateUtil.getTimeDisplay(
                        new Date(entry.end as string),
                        true,
                        true
                      )}
                      &nbsp;
                      {entry.isPrivate && <Tag label="PRIVATE" />}
                    </span>
                  </Text>
                </AccordionSummary>

                <AccordionDetails>
                  <StyledMapContainer>
                    <Link href={entry.directions || ''} target="_blank">
                      <Image
                        alt={`Map of ${entry.location.name}`}
                        height={278}
                        width={600}
                        src={mapsUtil.getStaticMapImage(
                          entry.location.coordinates,
                          '600x278',
                          'roadmap'
                        )}
                      />
                    </Link>
                  </StyledMapContainer>

                  <Link
                    display="underline"
                    highlight
                    href={entry.directions || ''}
                    target="_blank"
                  >
                    <Text
                      inherit
                      style={{
                        display: 'inline-block',
                        marginBottom: 8,
                        marginTop: 8,
                      }}
                    >
                      {strings.labels.getDirections}
                    </Text>
                  </Link>

                  {!!entry.lotId && (
                    <Link
                      display="underline"
                      highlight
                      href={`/events/${entry.lotId}`}
                      target="_blank"
                    >
                      <Text
                        inherit
                        style={{
                          display: 'inline-block',
                          marginBottom: 8,
                          marginLeft: 16,
                          marginTop: 8,
                        }}
                      >
                        {strings.labels.viewEvent}
                      </Text>
                    </Link>
                  )}
                </AccordionDetails>
              </StyledAccordion>
            );
          })}
        </StyledModalContent>
      </>
    );
  };

  return (
    <Modal
      aria-describedby="vendor-schedule"
      aria-labelledby="vendor-schedule-modal"
      header={
        <Text as="modalHeader">
          {vendor.uniqueName}&nbsp;{strings.titles.foodTruckSchedule}
        </Text>
      }
      {...props}
    >
      {renderContent()}
    </Modal>
  );
};

export default VendorSchedule;
