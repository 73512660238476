import React, { useRef } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';

export default function PdfViewer({
  file,
  scrollIntoView = true,
}: PdfViewerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={containerRef}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
        <Viewer
          fileUrl={file}
          onDocumentLoad={() =>
            scrollIntoView && containerRef.current?.scrollIntoView()
          }
        />
      </Worker>
    </div>
  );
}
