import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

import type { OrganizationTerritory } from '../../types/shared';
export const useOrganizationApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async getTerritories(
        organizationId: string
      ): Promise<OrganizationTerritory[]> {
        try {
          const data: OrganizationTerritory[] = await api.get(
            endpoints.organizations.territories,
            { organizationId }
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },
    };
  }, [api]);
};
