import { styled } from '../../../../theme/stitches.config';

export const StyledFooterLinkListWrapper = styled('nav', {
  display: 'flex',
  justifyContent: 'space-between',
  '@sm': {
    display: 'block',
    '> div + div': {
      marginTop: 24,
    },
  },
});

export const StyledFooterLinkList = styled('ul', {
  display: 'block',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  textAlign: 'left',
});

export const StyledSectionLabel = styled('h6', {
  color: '$Neutral07',
  fontFamily: 'Avenir',
  fontSize: '.625rem',
  fontWeight: 800,
  letterSpacing: '0.075rem',
  lineHeight: '1.75rem',
  margin: 0,
  textTransform: 'uppercase',
});
