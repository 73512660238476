import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * Order Request model.
 */
export const OptionGroupModel = types.model('OptionGroup', {
  id: types.string,
  allowMultiple: types.boolean,
  limit: types.maybe(types.number),
  name: types.string,
  options: types.array(
    types.model({
      name: types.string,
      price: types.maybe(types.number),
      value: types.string,
    })
  ),
  required: types.boolean,
});

type OptionGroupType = Instance<typeof OptionGroupModel>;
export interface OptionGroup extends OptionGroupType {}
type OptionGroupSnapshotType = SnapshotOut<typeof OptionGroupModel>;
export interface OptionGroupSnapshot extends OptionGroupSnapshotType {}
export const createOptionGroupDefaultModel = () =>
  types.maybe(OptionGroupModel);
