import env, { AppMode } from './env';

export default {
  mode: {
    isTesting: env.MODE === AppMode.TESTING.toString(),
    isDevelopment:
      env.NAME.toLowerCase() === 'development' ||
      env.NAME.toLowerCase() === 'local',
    isProduction: env.NAME.toLowerCase() === 'production',
    isStaging: env.NAME.toLowerCase() === 'staging',
  },
  settings: {
    countryCode: 'US',
    currencyCode: 'USD',
    locale: 'en-US',
    localeShort: 'en',
    ordering: {
      step: {
        key: {
          1: 'Submitted',
          2: 'Preparing',
          3: 'Ready',
          4: 'Complete',
          91: 'Cancelled',
          93: 'Refunded',
          95: 'Abandoned',
          123: 'InProgress',
        },
        description: {
          1: 'Order has been submitted.',
          2: 'Order is being prepared.',
          3: 'Order is ready for :method.', // pickup vs. delivery
          4: 'Order has been picked up', // 'Order has been :method.', // picked up vs. delivered
          91: 'Order has been cancelled.',
          93: 'Order has been refunded.',
          95: 'Order has been abandoned.',
          123: 'Order is in progress.',
        },
        name: {
          submitted: 'Submitted',
          prepared: 'Preparing',
          ready: 'Ready',
          complete: 'Complete',
          canceled: 'Cancelled',
          refunded: 'Refunded',
          abandone: 'Abandoned',
          inProgress: 'In Progress',
        },
        options: ['Submitted', 'Preparing', 'Ready', 'Complete'],
        shortDescription: {
          1: 'Submitted',
          2: 'Being prepared',
          3: 'Ready for :method3.', // pickup vs. delivery
          4: ':method4', // ':method.', // picked up vs. delivered
          91: 'Cancelled.',
          93: 'Refunded.',
          95: 'Abandoned.',
          123: 'In progress.',
        },
        values: {
          abandoned: 95,
          canceled: 91,
          complete: 4,
          inProgress: 123,
          preparing: 2,
          ready: 3,
          refunded: 93,
          submitted: 1,
        },
      },
      tipOptions: [
        {
          label: '10%',
          value: 0.1,
        },
        {
          label: '20%',
          value: 0.2,
          default: true,
        },
        {
          label: '25%',
          value: 0.25,
        },
        {
          label: '30%',
          value: 0.3,
        },
        {
          label: 'Other',
          value: 0,
        },
      ],
    },
  },
};
