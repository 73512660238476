import React, { useCallback } from 'react';
import Menu from '@mui/material/Menu';

import { DropdownItem } from '../../Dropdown';

import type { NavDropdownProps, NavLink } from './types';

export const renderItems = (items: NavLink[]) => {
  return items?.length ? (
    items.map((item) => <DropdownItem item={item} key={item.label} />)
  ) : (
    <span>There doesn&apos;t seem to be anything here....</span>
  );
};

const NavDropdown = ({
  items,
  open,
  anchorEl,
  handleClose,
}: NavDropdownProps) => {
  useCallback(() => {
    renderItems(items);
  }, [items]);

  return (
    <Menu
      anchorEl={anchorEl}
      id="basic-menu"
      onClose={handleClose}
      open={open}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {renderItems(items)}
    </Menu>
  );
};

export default NavDropdown;
