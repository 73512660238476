import React from 'react';
import { Backdrop, Fade, Modal as MuiModal } from '@mui/material';

import {
  StyledBody,
  StyledContainer,
  StyledContent,
  StyledFooter,
  StyledHeader,
  StyledIconButton,
} from './styles';
import type { ModalProps } from './types';
export default function Modal({
  children,
  close,
  closeButtonCss,
  containerCss = {
    maxWidth: 500,
  },
  contentCss,
  footer,
  header,
  headerCss,
  showClose = true,
  ...props
}: ModalProps): JSX.Element {
  const handleClose = () => {
    close();
  };

  return (
    <MuiModal
      BackdropComponent={Backdrop}
      closeAfterTransition
      onClose={handleClose}
      BackdropProps={{
        timeout: 300,
      }}
      {...props}
    >
      <section>
        <Fade in={props.open}>
          <StyledContainer css={containerCss}>
            <StyledBody>
              {header && <StyledHeader css={headerCss}>{header}</StyledHeader>}

              {header && showClose && (
                <StyledIconButton
                  aria-hidden={!props.open}
                  aria-label="Close Modal"
                  icon="close"
                  onClick={handleClose}
                  rank="tertiary"
                  style={closeButtonCss}
                />
              )}

              <StyledContent css={contentCss}>
                {!header && showClose && (
                  <StyledIconButton
                    icon="close"
                    onClick={handleClose}
                    rank="tertiary"
                    style={closeButtonCss}
                  />
                )}

                {children}
              </StyledContent>

              {footer && <StyledFooter>{footer}</StyledFooter>}
            </StyledBody>
          </StyledContainer>
        </Fade>
      </section>
    </MuiModal>
  );
}
