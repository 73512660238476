import { Text } from '@goodfynd/react-web.lib.ui';
import { styled } from '@goodfynd/react-web.theme';

export const StyledDiv = styled('div');

export const StyledFlex = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
});

export const StyledFlexBetweenH = styled('div', {
  display: 'flex',
  gap: 16,
  justifyContent: 'space-between',
});

export const StyledFlexBetweenV = styled('div', {
  alignItems: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

export const StyledFlexCenter = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

export const StyledSpan = styled('span');

export const StyledText09 = styled(Text, {
  color: '$Neutral09',
  fontSizeRem: 14,
  lineHeightRem: 21,
});

export const StyledText = styled(Text);
