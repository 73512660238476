import type { MenuOption } from '../../Menu/types';

export type NavLink = MenuOption;

export const MAIN_NAV = [
  {
    href: '/search',
    label: 'Find Food',
  },
  {
    href: '/vendors',
    label: 'Add Your Truck',
  },
  {
    href: '/events',
    label: 'Book Truck',
  },
];
