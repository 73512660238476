import React, { useCallback, useMemo } from 'react';
import type { IconName } from '@goodfynd/react-web.lib.ui';
import type { StepIconProps } from '@mui/material';
import { Step, StepLabel } from '@mui/material';

import app from '../../config/app';
import strings from '../../config/strings';

import {
  StyledIconCircle,
  StyledIconCircleActive,
  StyledTracker,
  StyledTrackerConnector,
  StyledTrackerIcon,
} from './styles';
import type { OrderTrackerProps } from './types';

const StepIcon = (icon: IconName = 'check') => {
  function StepIconComponent(props: StepIconProps) {
    const { completed, className } = props;

    return (
      <StyledTrackerIcon className={className}>
        {completed ? (
          <StyledIconCircleActive
            color="TextIconInverse"
            name={icon}
            css={
              icon === 'close'
                ? {
                    backgroundColor: 'var(--BackgroundInteractiveDanger)',
                  }
                : undefined
            }
          />
        ) : (
          <StyledIconCircle />
        )}
      </StyledTrackerIcon>
    );
  }
  return StepIconComponent;
};

export default function OrderTracker({
  order,
  showLastStep = true,
  ...props
}: OrderTrackerProps) {
  const steps = useMemo(() => {
    if (order.step === 91) {
      // canceled
      return [app.settings.ordering.step.options[0], strings.labels.canceled];
    }

    const steps = app.settings.ordering.step.options.slice(0);
    if (!showLastStep) {
      steps.pop();
    }

    return steps;
  }, [order.step, showLastStep]);

  const getActiveStep = useCallback(() => {
    if (order.step === 91) {
      // canceled
      return 2;
    }

    return order.step > 4 ? 4 : order.step;
  }, [order.step]);

  return (
    <StyledTracker
      activeStep={getActiveStep()}
      alternativeLabel
      connector={<StyledTrackerConnector />}
      {...props}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={StepIcon(
              order.step >= 90 && index > 0 ? 'close' : 'check'
            )}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </StyledTracker>
  );
}
