import React, { useState } from 'react';
import { Button, Icon, Link, Text } from '@goodfynd/react-web.lib.ui';

import PickupTimeOptions from '../../components/PickupTimeOptions';
import config from '../../config';
import routes from '../../config/routes';
import strings from '../../config/strings';
import type { UpdatePickupRequest } from '../../types/orders';
import { dateUtil } from '../../utils';

import OrderTracker from './OrderTracker';
import { StyledMerchantContainer, StyledOrderConfirmation } from './styles';
import type { OrderMerchantProps } from './types';

import classes from './OrderMerchant.module.css';

export default function OrderMerchant({
  customer,
  index = 0,
  order,
  showConfirmation,
  updatePickupTime,
  ...props
}: OrderMerchantProps) {
  const [viewPickupTimeOptions, togglePickupTimeOptions] = useState(false);

  const handlePickupUpdate = async (pickupTime: Date) => {
    const request: UpdatePickupRequest = {
      id: order.id,
      pickupTime,
      scheduleId: order.scheduleId,
    };

    try {
      updatePickupTime(request);
      togglePickupTimeOptions(false);
    } catch (error) {
      console.warn(error);
    }
  };

  const customerEmail = order.customer?.email || customer?.email;
  return (
    <>
      <StyledMerchantContainer
        {...props}
        css={{
          ...props.css,
          borderTop: index > 0 ? '5px solid $Neutral02' : undefined,
          marginTop: 16,
        }}
      >
        {showConfirmation && (
          <StyledOrderConfirmation>
            <Text as="heading5">
              {order.step === 91
                ? strings.titles.orderCanceled
                : order.step === 93
                ? strings.titles.orderRefunded
                : strings.titles.orderReceived}
            </Text>

            {order.step >= 90 ? (
              <Text as="body2">
                {order.cancellationReason || order.refundNotes}
              </Text>
            ) : (
              customerEmail && (
                <Text as="body2">{`${strings.titles.confirmationEmailSent} ${customerEmail}`}</Text>
              )
            )}

            <OrderTracker order={order} />

            <Link
              href={`${config.env.RECEIPT_HOST}/orders/receipt/image/${order.transactionId}`}
            >
              <Text
                as="subhead2"
                inherit
                style={{ display: 'inline-block', marginLeft: 24 }}
              >
                {strings.labels.viewReceipt}
              </Text>
            </Link>

            <Link href={routes.search.products}>
              <Text
                as="subhead2"
                inherit
                style={{ display: 'inline-block', marginLeft: 24 }}
              >
                {strings.labels.findMoreFood}
              </Text>
            </Link>
          </StyledOrderConfirmation>
        )}

        <Link
          display="underline-none"
          href={`/vendors/${order.merchant.nameId}`}
        >
          <Text as="subhead2">{order.merchant.name}</Text>
        </Link>

        {order.pickupTime && (
          <>
            <Link
              display="underline"
              href={order.pickupLocation.directions as string}
            >
              <Icon
                containerClass={classes['icon-label']}
                inherit
                name="location_pin"
                label={
                  order.pickupLocation.name || order.pickupLocation.address
                }
              />
            </Link>

            {!!order.pickupTime && (
              <Icon
                containerClass={classes['icon-label']}
                name="calendar"
                label={dateUtil.format(
                  order.pickupTime as Date,
                  config.dateFormats.weekDayMonthDay
                )}
              />
            )}

            {!!(order.pickupTimeEnd && order.pickupTimeStart) && (
              <Icon
                containerClass={classes['icon-label']}
                name="clock"
                label={dateUtil.getTimeDisplay(
                  new Date(order.pickupTime as Date),
                  true,
                  true
                )}
              />
            )}
          </>
        )}

        {order.step === 0 && (
          <Button
            display="compact"
            onClick={() => togglePickupTimeOptions(true)}
            rank="tertiary"
            size="small"
          >
            {order.pickupTime
              ? strings.labels.updatePickupTime
              : strings.labels.choosePickupTime}
          </Button>
        )}
      </StyledMerchantContainer>

      {viewPickupTimeOptions && (
        <PickupTimeOptions
          close={() => togglePickupTimeOptions(false)}
          onContinue={handlePickupUpdate}
          open={viewPickupTimeOptions}
          scheduleId={order.scheduleId}
          vendorId={order.merchant.nameId}
        />
      )}
    </>
  );
}
