import React from 'react';
import { Link, Text } from '@goodfynd/react-web.lib.ui';

import strings from '../../../config/strings';
import { LEGAL_LINKS } from '../Footer/footer-links';

import { StyledLegal } from './styles';

export default function FooterLegal() {
  return (
    <StyledLegal>
      <ul>
        {LEGAL_LINKS.map((link) => (
          <li key={link.label}>
            <Link href={link.href || ''}>
              <Text as="bottomNavItem">{link.label}</Text>
            </Link>
          </li>
        ))}
      </ul>

      <Text as="bottomNavItem">
        &copy;{new Date().getFullYear()} {strings.app.nameLegal}
      </Text>
    </StyledLegal>
  );
}
