import { DefaultColors } from '../../../theme';
import { styled } from '../../../theme/stitches.config';
import Container from '../Container';

import { StyledFooterLinkListWrapper } from './FooterLinkList/styles';

export const StyledFooterContainer = styled(Container, {
  backgroundColor: DefaultColors.Neutral12,
  '@sm': {
    padding: '32px 28px !important',
  },
  '@md': {
    padding: '32px 48px !important',
  },
  '@lg': {
    padding: '32px 120px !important',
  },
});

export const StyledFooter = styled('footer', {
  backgroundColor: DefaultColors.Neutral12,
});

export const StyledLegalContainer = styled('div', {
  backgroundColor: DefaultColors.Neutral13,
});

export const StyledLinkListWrapper = styled(StyledFooterLinkListWrapper, {
  marginTop: 24,
  maxWidth: 720,
});

export const StyledSocialContainer = styled('ul', {
  display: 'flex',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  'li+li': {
    color: 'var(--TextIconInverse)',
    paddingLeft: 16,
  },
  variants: {
    position: {
      bottom: {
        marginTop: 24,
        '@md': {
          display: 'none',
        },
      },
      right: {
        '@sm': {
          display: 'none',
        },
      },
    },
  },
});

export const StyledTopRow = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
