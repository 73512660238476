import { decode } from 'html-entities';

import app from './app';
import env from './env';
import routes from './routes';

export enum AppMode {
  Admin = 1,
  Normal = 0,
  Testing = 2,
}

export const emojis = {
  fries: '🍟',
  happy: '😃',
  hundred: '💯',
  sad: '😢',
  smile: '😊',
  surprised: '😱',
};

export const unicode = {
  bullet: '\u2022',
  bulletPadded: decode('&nbsp;\u2022&nbsp;'),
  bulletLarge: '\u2B24',
  centerDot: decode('\u00B7'),
  centerDotPadded: decode('&nbsp;\u00B7&nbsp;'),
  check: '\u2713',
  dashEm: '\u2014',
  dashEn: '\u2013',
  minus: '\u2212',
  plus: '\u002B',
  plusHeavy: '\u2795',
  plusLarge: '\uFF0B',
  rightAngle: '\u203A',
  rightAngleDouble: '\u00BB',
  rightArrowhead: '\u003E',
  space: decode('&nbsp;'),
  tm: decode('&trade;'),
};

export default {
  app,
  env,
  routes,
  coordinates: '39.1157,-77.5633',
  dateFormats: {
    dayHourM: 'eee, ha',
    dayOfWeek: 'EEEE',
    dayTime: 'MM/dd/yyyy h:mm a',
    hour: 'h',
    hourLeadingZero: 'hh',
    hourM: 'ha',
    hourMin: 'h:mma',
    hour24: 'H',
    hour24LeadingZero: 'HH',
    meridiem: 'aaa',
    minute: 'mm',
    month: 'MMMM',
    monthDay: 'MMM do',
    monthDayYear: 'MM/dd/yyyy',
    monthDayYearShort: 'MM/dd/yy',
    monthYear: 'MMMM yyyy',
    time: 'h:mma',
    timeSpace: 'h:mm a',
    weekDay: 'eeee',
    weekDayMonthDay: 'eee, MMM do',
    weekDayMonthDayTime: 'eee, MMM do, h:mm a',
    weekDayMonthDayTimeShort: 'eee, MMM do, h:mm a',
    weekDayMonthDayYear: 'eee, MMM dd, yyyy',
    weekDayMonthDayYearLong: 'eeee, MMMM dd, yyyy',
    year: 'yyyy',
    yearMonthDay: 'yyyyMMdd',
  },
  defaultLocation: {
    coordinates: '38.805, -77.051',
    latitude: 38.805,
    longitude: -77.047,
  },
  emojis,
  queryKeys: {
    auth: 'fynd.auth',
    cart: 'fynd.query.cart',
    cms: 'fynd.query.cms',
    event: 'fynd.query.event',
    lotEvents: 'fynd.query.lot.events',
    openVendors: 'fynd.query.open.vendors',
    organizationTerritories: 'fynd.query.organization.territories',
    searchEvents: 'search-events',
    searchProducts: 'search-products',
    searchVendors: 'search-vendors',
    transactions: 'fynd.query.transactions',
    userLocation: 'fynd.user.location',
    vendor: 'fynd.query.vendor',
    vendorProducts: 'fynd.query.vendor.products',
  },
  storageKeys: {
    appleAuth: 'fynd.apple.auth',
    colorScheme: 'fynd.color.scheme',
    guestOrder: 'fynd.guest.order',
    rootState: 'fynd.root',
    userLocation: 'fynd.user.location',
  },
  unicode,
};

export function showOopsAlert() {
  alert(`An unknown error has occurred ${emojis.sad}.`);
}

export function showTimedOutAlert() {
  alert(`Application timed out ${emojis.sad}.`);
}
