import React from 'react';
import { unicode } from '@goodfynd/react-web.lib.strings';
import { ButtonLink, Icon, Text } from '@goodfynd/react-web.lib.ui';
import { StyledFlex } from '@goodfynd/react-web.styles';
import { numberUtil } from '@goodfynd/react-web.utils.number-util';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import routes from '../../config/routes';
import strings from '../../config/strings';

import {
  StyledInputTip,
  StyledItemRow,
  StyledTooltip,
  StyledTotalsContainer,
} from './styles';
import type { OrderTotalsProps } from './types';

export default function OrderTotals({
  discount,
  fees,
  feesTotal,
  showCheckout,
  showTip,
  subTotal,
  total,
  tip = 0,
  tipAmount = 0,
  updateTipAmount,
  ...props
}: OrderTotalsProps): JSX.Element {
  const [feesTipOpen, setFeesTipOpen] = React.useState(false);
  const handleFeesTipClose = () => {
    setFeesTipOpen(false);
  };

  const handleFeesTipOpen = () => {
    setFeesTipOpen(true);
  };

  const feesAmount =
    feesTotal || fees.map((fee) => fee.value).reduce((a, b) => a + b, 0);
  return (
    <StyledTotalsContainer {...props}>
      <StyledItemRow>
        <Text as="subhead2">{strings.labels.subtotal}</Text>

        <Text as="subhead2">{numberUtil.renderPrice(subTotal)}</Text>
      </StyledItemRow>

      {discount > 0 && (
        <StyledItemRow>
          <Text as="subhead2">{strings.labels.discount}</Text>

          <Text as="subhead2" color="TextIconFavorable">
            {unicode.dashEn}
            {numberUtil.renderPrice(discount)}
          </Text>
        </StyledItemRow>
      )}

      {tip > 0 && (
        <StyledItemRow>
          <Text as="subhead2">{strings.labels.tip}</Text>

          <Text as="subhead2">{numberUtil.renderPrice(tip)}</Text>
        </StyledItemRow>
      )}

      {feesAmount > 0 && (
        <StyledItemRow>
          <ClickAwayListener onClickAway={handleFeesTipClose}>
            <div
              onClick={handleFeesTipOpen}
              onMouseOver={handleFeesTipOpen}
              style={{ cursor: 'pointer' }}
            >
              <StyledTooltip
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={handleFeesTipClose}
                open={feesTipOpen}
                PopperProps={{
                  disablePortal: true,
                }}
                title={
                  <>
                    {fees.map((fee, index) => (
                      <>
                        <Text
                          as="subhead2"
                          style={index ? { marginTop: 8 } : undefined}
                        >
                          {fee.name}:&nbsp;{numberUtil.renderPrice(fee.value)}
                        </Text>
                        <Text as="caption">
                          <em>{fee.description}</em>
                        </Text>
                      </>
                    ))}
                  </>
                }
              >
                <StyledFlex gap={4}>
                  <Text as="subhead2">{strings.labels.taxesAndFees}</Text>
                  <Icon name="info" size={16} />
                </StyledFlex>
              </StyledTooltip>
            </div>
          </ClickAwayListener>

          <Text as="subhead2">{numberUtil.renderPrice(feesAmount)}</Text>
        </StyledItemRow>
      )}

      {showTip && total > 0 && (
        <StyledInputTip
          onChange={(tipAmount) => updateTipAmount?.(tipAmount)}
          subTotal={subTotal}
        />
      )}

      <StyledItemRow css={{ marginTop: '24px !important' }}>
        <Text as="subhead2">{strings.labels.total}</Text>

        <Text as="subhead2">{numberUtil.renderPrice(total + tipAmount)}</Text>
      </StyledItemRow>

      {showCheckout && (
        <ButtonLink
          fullWidth
          href={routes.checkout.home}
          margin="16px 0 0 "
          rank="primary"
        >
          {strings.labels.proceedToCheckout}
        </ButtonLink>
      )}
    </StyledTotalsContainer>
  );
}
