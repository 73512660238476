import { cast, destroy, Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';
import { UserModel, UserSnapshot } from '../models/user';

export const AuthStore = types
  .model('AuthStore', {
    busy: false,
    isLoggedIn: false,
    user: types.maybe(UserModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setBusy: (busy = true) => {
      self.busy = busy;
    },
    setUser: (userSnapshot?: UserSnapshot) => {
      self.busy = false;
      self.isLoggedIn = true;
      self.user = cast(userSnapshot);
    },
    unsetUser: () => {
      self.busy = false;
      self.isLoggedIn = false;
      self.user && destroy(self.user);
      return true;
    },
  }));

type AuthStoreType = Instance<typeof AuthStore>;
export type AuthStore = AuthStoreType;
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStore>;
export type AuthStoreSnapshot = AuthStoreSnapshotType;

let _authStore: AuthStore;
export const useAuthStore = () => {
  if (!_authStore) {
    _authStore = AuthStore.create({
      busy: false,
      isLoggedIn: false,
    });
  }

  return _authStore;
};
