import React from 'react';

import { StyledDropdown } from './styles';
import type { NavWrapperProps } from './types';
import { SimpleNav } from './';

const NavWrapper = ({
  links,
  variant = 'vertical',
}: NavWrapperProps): JSX.Element => {
  return (
    <nav>
      <StyledDropdown variant={variant}>
        {links
          ?.filter((link) => !link.hide)
          .map((item) => (
            <SimpleNav key={item.label} {...item} />
          ))}
      </StyledDropdown>
    </nav>
  );
};

export default NavWrapper;
