import { useMemo } from 'react';

import config from '../../config';
import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import { storageUtil, stringUtil } from '../../utils';

import type { OrderRequest } from '../../stores/models/order-request';
import type { CartOrder } from '../../types/cart';
import type { OrderSearchRequest } from '@goodfynd/react-web.types';

import type {
  BasicCustomer,
  CreditCard,
  RemoveItemRequest,
  UpdatePickupRequest,
} from '../../types/orders';
import type {
  CartResponse,
  CheckoutCustomerResponse,
  CreditCardResponse,
  LikeResponse,
  OrderResponse,
  OrderSearchResponse,
  PaymentResponse,
  TransactionsResponse,
} from '../../services/api/types';

export enum OrderSort {
  PickupTimeAsc = '1',
  PickupTimeDesc = '2',
  TimeCreatedAsc = '3',
  TimeCreatedDesc = '4',
  DueDateAsc = '5',
  DueDateDesc = '6',
  TimeCompletedAsc = '7',
  TimeCompletedDesc = '8',
}

export const useOrderApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async acceptQuote(id: string): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replaceAll(endpoints.invoices.quote.accept, {
              ':id': id,
            })
          );

          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async addInvoiceTip(id: string, tip: number): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replaceAll(endpoints.invoices.tip, {
              ':id': id,
              ':tip': tip.toString(),
            })
          );

          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async addItem(
        request: OrderRequest,
        orderId = ''
      ): Promise<CartResponse> {
        try {
          const data: CartResponse = await api.post(
            stringUtil.replace(endpoints.orders.base, { ':id': orderId }),
            {
              ...request,
              sector: 'trucks',
            }
          );
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async applyPromo(code: string): Promise<CartResponse> {
        try {
          const data: CartResponse = await api.put(
            stringUtil.replace(endpoints.orders.promo, { ':code': code })
          );
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async finalizeInvoice(id: string, tip: number): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replaceAll(endpoints.invoices.finalize, {
              ':id': id,
              ':tip': tip.toString(),
            })
          );

          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async getCart(id: string): Promise<CartResponse> {
        try {
          const data: CartResponse = await api.get(
            stringUtil.replace(endpoints.carts.cart, {
              ':id': id,
            })
          );
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async getOrder(id: string): Promise<OrderResponse> {
        try {
          const order: CartOrder = await api.get(
            stringUtil.replace(endpoints.orders.base, {
              ':id': id,
            })
          );

          return {
            order,
            success: true,
          };
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async getTransactions(ids: string): Promise<TransactionsResponse> {
        try {
          const data: TransactionsResponse = await api.get(
            endpoints.orders.transactions,
            {
              ids,
            }
          );
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async payCredit(payment: Partial<CreditCard>): Promise<PaymentResponse> {
        try {
          const data: PaymentResponse = await api.post(
            endpoints.checkout.pay.credit,
            payment
          );
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async removeCreditCard(card: CreditCard): Promise<CreditCardResponse> {
        const data: CreditCardResponse = await api.delete(
          endpoints.stripe.card,
          card
        );

        return data;
      },

      async removeItem(
        productId: string,
        request: RemoveItemRequest
      ): Promise<CartResponse> {
        try {
          const data: CartResponse = await api.post(
            stringUtil.replace(endpoints.orders.remove, { ':id': productId }),
            {
              ...request,
              quantity: 0,
              sector: 'trucks',
            }
          );
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async search(query: OrderSearchRequest): Promise<OrderSearchResponse> {
        try {
          const data: OrderSearchResponse = await api.get(
            endpoints.orders.search,
            {
              rows: 24,
              sector: 'trucks',
              ...query,
            } as OrderSearchRequest
          );
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async toggleLike(id: string, liked: boolean): Promise<LikeResponse> {
        try {
          const data: LikeResponse = await (liked ? api.delete : api.put)(
            stringUtil.replace(endpoints.orders.like, { ':id': id })
          );
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async updateCustomer(
        customer: BasicCustomer
      ): Promise<CheckoutCustomerResponse> {
        try {
          const data: CheckoutCustomerResponse = await api.post(
            stringUtil.replace(endpoints.customers.base, { ':id': '' }),
            {
              ...customer,
              orderId: storageUtil.local.get(config.storageKeys.guestOrder),
            }
          );
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async updatePickupTime(
        request: UpdatePickupRequest
      ): Promise<CartResponse> {
        try {
          const data: CartResponse = await api.put(endpoints.orders.pickup, [
            request,
          ]);
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
