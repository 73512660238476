import React from 'react';
import type { FooterProps } from '@goodfynd/react-web.layout.footer';
import { Footer } from '@goodfynd/react-web.layout.footer';
import type {
  CmsContentProps,
  FaqsProps,
  StructuredColumnsProps,
  TwoColumnProps,
} from '@goodfynd/react-web.lib.content';
import {
  CmsContent,
  Faqs,
  StructuredColumns,
  TwoColumn,
} from '@goodfynd/react-web.lib.content';
import { ButtonLink } from '@goodfynd/react-web.lib.ui';

import env from '../../config/env';

import type { ComponentProps, ComponentType } from './types';
export default function renderCmsBodyComponent(
  index: number,
  componentType: ComponentType,
  fields: any,
  { containerCSS }: ComponentProps = {}
): JSX.Element | null {
  switch (componentType) {
    case 'button':
      const { button_href, button_text, return_to_page } = fields as any;

      return !!button_href && !!button_text ? (
        <ButtonLink
          key={componentType + index}
          href={`${button_href}${
            return_to_page ? `?returnUrl=${window.location.href}` : ''
          }`}
        >
          {button_text}
        </ButtonLink>
      ) : null;

    case 'content_section':
      return (
        <CmsContent
          containerCSS={containerCSS}
          {...(fields as CmsContentProps)}
          key={componentType + index}
        />
      );

    case 'content_with_image_columns':
      return (
        <StructuredColumns
          {...(fields as StructuredColumnsProps)}
          key={componentType + index}
        />
      );

    case 'event_faqs':
      return <Faqs {...(fields as FaqsProps)} key={componentType + index} />;

    case 'footer':
      return (
        <Footer
          drift_interaction_id={env.DRIFT_INTERACTION_ID}
          key={componentType + index}
          {...(fields.footers?.[0] as unknown as FooterProps)}
        />
      );

    case 'two_column_images':
      return (
        <TwoColumn
          {...(fields as TwoColumnProps)}
          key={componentType + index}
        />
      );

    case 'vendor_faqs':
      return <Faqs {...(fields as FaqsProps)} key={componentType + index} />;

    default:
      return null;
  }
}
