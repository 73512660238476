import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Alert,
  Icon,
  Image,
  Link,
  Loading,
  Modal,
  Tag,
  Text,
} from '@goodfynd/react-web.lib.ui';
import { AccordionDetails, AccordionSummary } from '@mui/material';

import config from '../../config';
import strings from '../../config/strings';
import { VendorApi } from '../../services/api';
import type { VendorResponse } from '../../services/api/types';
import { useStores } from '../../stores';
import type { PickupTimeOption } from '../../types/vendors';
import { dateUtil, mapsUtil, vendorUtil } from '../../utils';
import InputRadioGroupControl from '../forms/InputRadioGroup/InputRadioGroupControl';

import {
  StyledAccordion,
  StyledButton,
  StyledMapContainer,
  StyledModalContent,
  StyledStickyBottom,
} from './styles';
import type { PickupTimeOptionsProps } from './types';

import classes from './PickupTimeOptions.module.css';

const PickupTimeOptions = ({
  close,
  onContinue,
  schedule,
  scheduleId,
  vendorId,
  ...props
}: PickupTimeOptionsProps) => {
  const {
    orderStore: { updateEvent, ...orderStore },
  } = useStores();

  const { data: { vendor } = {}, isLoading } = useQuery<
    VendorResponse,
    unknown
  >(
    [config.queryKeys.vendor, vendorId],
    () => {
      const api = new VendorApi();
      return api.get(vendorId);
    },
    {
      enabled: !!vendorId,
      refetchOnMount: true,
    }
  );

  const [event, setEvent] = useState<OperatingDayLocation | undefined>(
    schedule?.find((entry) => entry.id === orderStore.event?.scheduleId)
  );

  const [events, setEvents] = useState<OperatingDayLocation[] | undefined>(
    schedule
  );

  const [pickupTime, setPickupTime] = useState<Date | undefined>(
    orderStore.event?.pickupTime
  );
  const [pickupTimes, setPickupTimes] = useState<PickupTimeOption[]>([]);

  const [expanded, setExpanded] = React.useState('');

  useEffect(() => {
    if (vendor?.id && !events) {
      const vendorEvents = vendorUtil.getPickupLocations(vendor);
      setEvents(vendorEvents);
      setEvent(
        vendorEvents.find((entry) => entry.id === orderStore.event?.scheduleId)
      );
    }
  }, [events, orderStore.event?.scheduleId, vendor]);

  const handleChange = useCallback(
    (entry: OperatingDayLocation) =>
      (_e: React.SyntheticEvent, isExpanded: boolean) => {
        if (!vendor) {
          return;
        }

        const locationPickupTimes = vendorUtil.getPickupTimes(vendor, entry);
        setPickupTimes(locationPickupTimes);
        setEvent(entry);
        setExpanded(isExpanded ? entry.id : '');
      },
    [vendor]
  );

  const locations = useMemo(
    () =>
      events?.filter((entry) => !scheduleId || entry.id === scheduleId) || [],
    [events, scheduleId]
  );
  const hasLocations = useMemo(() => !!locations[0], [locations]);

  const renderContent = useCallback(() => {
    const pickupTimeOptions: InputOption[] = [];
    const options = pickupTimeOptions.concat(
      ...pickupTimes.map((group) => group.options as InputOption[])
    );
    return (
      <>
        <StyledModalContent {...props}>
          {hasLocations ? (
            locations.map((entry) => (
              <StyledAccordion
                expanded={expanded === entry.id}
                key={entry.id}
                onChange={handleChange(entry)}
              >
                <AccordionSummary
                  aria-controls={`${entry.location.name || entry.id}-content`}
                  expandIcon={<Icon name="chevron_down" />}
                  id={entry.id}
                >
                  <Text>{entry.location.name || entry.location.address}</Text>
                  <Text as="body2">
                    <span>
                      {dateUtil.format(
                        new Date(entry.start as string),
                        config.dateFormats.weekDayMonthDay
                      )}
                      {config.unicode.centerDotPadded}
                      {dateUtil.getTimeDisplay(
                        new Date(entry.start as string),
                        true,
                        true
                      )}
                      &nbsp;-&nbsp;
                      {dateUtil.getTimeDisplay(
                        new Date(entry.end as string),
                        true,
                        true
                      )}
                      &nbsp;
                      {entry.isPrivate && <Tag label="PRIVATE" />}
                    </span>
                  </Text>
                </AccordionSummary>

                <AccordionDetails>
                  <StyledMapContainer>
                    <Image
                      alt={`Map of ${entry.location.name}`}
                      height={278}
                      width={600}
                      src={mapsUtil.getStaticMapImage(
                        entry.location.coordinates,
                        '600x278',
                        'roadmap'
                      )}
                    />
                  </StyledMapContainer>

                  <Link
                    display="underline"
                    highlight
                    href={entry.directions || ''}
                    target="_blank"
                  >
                    <Text
                      inherit
                      style={{
                        display: 'inline-block',
                        marginBottom: 8,
                        marginTop: 8,
                      }}
                    >
                      {strings.labels.getDirections}
                    </Text>
                  </Link>

                  {options.length > 0 ? (
                    <InputRadioGroupControl
                      onChange={(_e, value) => setPickupTime(value)}
                      options={options}
                      showLabel={false}
                      value={pickupTime && new Date(pickupTime).toString()}
                    />
                  ) : (
                    <Alert style={{ marginTop: 16 }}>
                      {strings.labels.noPickupTimes}
                    </Alert>
                  )}
                </AccordionDetails>
              </StyledAccordion>
            ))
          ) : (
            <Alert className={classes['no-times']}>
              {strings.labels.noPickupTimes}
            </Alert>
          )}
        </StyledModalContent>
      </>
    );
  }, [
    expanded,
    handleChange,
    hasLocations,
    locations,
    pickupTime,
    pickupTimes,
    props,
  ]);

  if (isLoading) {
    return <Loading type="line" />;
  }

  if (!events || !vendor) {
    return null;
  }

  return (
    <Modal
      aria-describedby="ordering-pickup-options"
      aria-labelledby="pickup-options-modal"
      close={close}
      disableEscapeKeyDown
      footer={
        <StyledStickyBottom>
          <StyledButton
            disabled={!event || !pickupTime || !hasLocations}
            onClick={() => {
              if (pickupTime) {
                updateEvent(vendor, event as OperatingDayLocation, pickupTime);
                onContinue(new Date(pickupTime));
              }
            }}
          >
            {strings.labels.continue}
          </StyledButton>
        </StyledStickyBottom>
      }
      header={
        <Text as="modalHeader">
          {orderStore.event?.pickupTime
            ? strings.titles.changePickupOptions
            : strings.titles.choosePickupOptions}
        </Text>
      }
      {...props}
    >
      {renderContent()}
    </Modal>
  );
};

export default PickupTimeOptions;
