import { Link } from '@goodfynd/react-web.lib.ui';
import { styled } from '@goodfynd/react-web.theme';

export const StyledDropdown = styled('ul', {
  display: 'none',
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  '@lg': {
    display: 'initial',
  },
  variants: {
    variant: {
      horizontal: {
        display: 'flex',
        flexDirection: 'row',
      },
      vertical: {
        display: 'block',
      },
    },
  },
});

export const StyledNavLink = styled(Link, {
  paddingLeft: '8px !important',
  paddingRight: '8px !important',
  textTransform: 'none !important',
});
