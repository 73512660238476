import { styled } from '../../../theme/stitches.config';

export const StyledLegal = styled('div', {
  justifyContent: 'space-between',
  margin: '0 auto',
  maxWidth: '1440px',
  p: {
    flex: 'none',
  },
  ul: {
    display: 'flex',
    justifyContent: 'space-between',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    '@xs': {
      display: 'initial',
    },
    '@sm': {
      marginBottom: 24,
    },
    '@md': {
      marginRight: 0,
    },
    '@lg': {
      marginRight: '65px',
    },
    li: {
      paddingRight: '20px',
    },
  },
  '@sm': {
    padding: '20px 28px !important',
  },
  '@md': {
    padding: '10px 48px !important',
    display: 'flex',
  },
  '@lg': {
    padding: '10px 120px !important',
    display: 'flex',
  },
});
