import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import * as stringUtil from '../../utils/string-util';

import type { SearchRequest } from '../../types/search';
import type {
  MetaResponse,
  ProductResponse,
  ProductSearchResponse,
} from '../../services/api/types';
export const useProductApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async get(id: string): Promise<ProductResponse> {
        try {
          const data: ProductResponse = await api.get(
            stringUtil.replaceAll(endpoints.products.base, {
              ':id': id,
              ':includeSchedule': 'false',
            }),
            undefined,
            {
              headers: {
                pragma: 'no-cache',
                'cache-control': 'no-cache',
              },
            }
          );
          return { success: true, ...data };
        } catch (error: any) {
          return { success: false, ...error };
        }
      },

      async search(query: SearchRequest): Promise<ProductSearchResponse> {
        try {
          const data: ProductSearchResponse = await api.get(
            endpoints.products.search,
            {
              open: false,
              rows: 24,
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return { success: true, ...data };
        } catch (error: any) {
          return { success: false, ...error };
        }
      },
    };
  }, [api]);
};
