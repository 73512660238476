import { styled } from '@goodfynd/react-web.theme';
import { Container } from '@mui/material';

export const StyledContainer = styled(Container, {
  variants: {
    fullWidth: {
      true: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      },
    },
    position: {
      footer: {
        '@sm': {
          padding: '32px 28px',
        },
        '@lg': {
          padding: '32px 120px',
        },
      },
      page: {
        '@lg': {
          height: '100%',
        },
      },
    },
  },
});
