import { Container } from '@goodfynd/react-web.layout.container';
import { styled } from '@goodfynd/react-web.theme';
import Avatar from '@mui/material/Avatar';

import Menu from '../../Menu';

export const StyledAvatar = styled(Avatar, {
  '&.MuiAvatar-root': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 'bold',
    height: 24,
    width: 24,
    zIndex: 1,
  },
  variants: {
    loggedIn: {
      true: {
        '&.MuiAvatar-root': {
          backgroundColor: 'var(--BackgroundBrand)',
        },
      },
    },
  },
});

export const StyledContainer = styled(Container, {
  '&.MuiContainer-root': {
    alignItems: 'center',
    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '64px',
  },
});

export const StyledHeader = styled('header', {
  backgroundColor: 'var(--BackgroundBase1)',
  top: 0,
  width: '100%',
  zIndex: 5,
  variants: {
    fixed: {
      true: {
        position: 'fixed',
      },
    },
    shadow: {
      true: {
        boxShadow:
          '0px 4px 20px rgba(0, 0, 0, 0.05), 0px 2px 12px rgba(0, 0, 0, 0.05)',
      },
    },
  },
});

export const StyledMoreIconContainer = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  '@lgMin': {
    display: 'none',
  },
});

export const StyledNavRight = styled('aside', {
  alignItems: 'center',
  display: 'flex',
  '> nav': {
    display: 'none',
    '@lg': {
      display: 'initial',
    },
  },
});

export const StyledUserMenu = styled(Menu, {
  '.MuiPaper-root': {
    border: '2px solid $Neutral05',
    marginTop: 8,
  },
});
