import type { IconName } from '@goodfynd/react-web.lib.ui';

import config from '../../../config';
import routes from '../../../config/routes';

export interface FooterLinkSection {
  hide?: boolean;
  links: FooterLink[];
  name: string;
}

export type FooterLink =
  | {
      hide?: boolean;
      href: string;
      icon?: IconName;
      label?: string;
      onClick?: never;
    }
  | {
      hide?: boolean;
      href?: never;
      icon?: IconName;
      label?: string;
      onClick: () => void;
    };

export const LEGAL_LINKS: FooterLink[] = [
  {
    label: 'Privacy Policy',
    href: routes.legal.privacy,
  },
  {
    label: 'Fulfillment Policy',
    href: routes.legal.fulfillment,
  },
  {
    label: 'Terms of Service',
    href: routes.legal.terms,
  },
  {
    label: 'Cookie Policy',
    href: routes.legal.cookies,
  },
  {
    label: 'Privacy Notice California',
    href: routes.legal.california,
  },
];

export const SOCIALS: FooterLink[] = [
  {
    icon: 'twitter',
    href: routes.social.twitter,
  },
  {
    icon: 'linkedin',
    href: routes.social.linkedIn,
  },
  {
    icon: 'instagram',
    href: routes.social.instagram,
  },
  {
    icon: 'facebook',
    href: routes.social.facebook,
  },
];

export const FOOTER_NAV: FooterLinkSection[] = [
  {
    name: 'Let Us Help You',
    links: [
      {
        label: 'Find a Truck',
        href: routes.search.vendors,
      },
      {
        label: 'Book a Truck',
        href: routes.events.home,
      },
      {
        label: 'List Your Truck',
        href: routes.vendors.home,
      },
    ],
  },
  {
    name: 'Your Account',
    links: [
      {
        label: 'Account Details',
        href: routes.account.home,
      },
      {
        hide: true,
        label: 'Order History',
        href: routes.account.orders,
      },
    ],
  },
  {
    name: 'Support',
    links: [
      {
        label: 'Help',
        hide: true,
        href: routes.help.home,
      },
      {
        label: 'Chat With Us',
        onClick: () =>
          window.drift?.api.startInteraction({
            interactionId: config.env.DRIFT_INTERACTION_ID,
          }),
      },
    ],
  },
  {
    name: 'Get to Know Us',
    links: [
      {
        hide: true,
        label: 'About Us',
        href: routes.about,
      },
      {
        label: 'Careers',
        href: routes.careers,
      },
      {
        label: 'Blog',
        href: routes.blog,
      },
    ],
  },
];
