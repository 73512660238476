import { useQuery } from 'react-query';

import config from '../config';
import { useAccountApi } from './api';

import type {
  UserInfoError,
  UserInfoResponse,
} from '../stores/auth-store/types';
import type { UserInfo } from '../stores/models/user';
type UserHookOptions = {
  setGuest: () => void;
  setUser: (user: UserInfo) => void;
  notFound: () => void;
};

export default function useUser({
  setGuest,
  setUser,
  notFound,
}: UserHookOptions) {
  const api = useAccountApi();

  const { data, isFetched, isFetching, isLoading, refetch } = useQuery<
    UserInfoResponse,
    UserInfoError
  >(
    [config.queryKeys.auth],
    async () => {
      const data = await api.userInfo();
      return {
        ...data,
        user: {
          ...data.user,
          profiles: data.user.profiles?.sort((a, b) =>
            a.label > b.label ? 1 : -1
          ),
        },
      };
    },
    {
      onError: (err) => {
        console.log('err', err);
        switch (err.status) {
          case 404:
            notFound();
          default:
            setGuest();
        }
      },
      onSuccess: (data) => {
        setUser(data.user);
        console.debug('user.profile.updated: ', data.user.email);
      },
    }
  );

  return {
    isFetched,
    isFetching,
    isLoading,
    isLoggedIn: !!data?.user?.id,
    refetch,
    user: data?.user,
  };
}
