import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * UserInfo model.
 */
export const UserInfoModel = types.model('UserInfo').props({
  avi: types.maybe(types.string),
  defaultVendorId: types.maybe(types.string),
  defaultLotId: types.maybe(types.string),
  email: types.string,
  id: types.identifier,
  isGuest: types.boolean,
  initials: types.maybe(types.string),
  firstName: types.maybe(types.string),
  fullName: types.maybe(types.string),
  isAdmin: types.boolean,
  isAuthenticated: types.maybe(types.boolean),
  isLotOwner: types.maybe(types.boolean),
  isOrganizationManager: types.maybe(types.boolean),
  isOrganizationOwner: types.maybe(types.boolean),
  isRestrictedAdmin: types.boolean,
  isSuperAdmin: types.boolean,
  isVendorManager: types.maybe(types.boolean),
  isVendorOwner: types.maybe(types.boolean),
  lastName: types.maybe(types.string),
  phone: types.maybe(types.string),
  postalCode: types.maybe(types.string),
  profiles: types.optional(
    types.array(
      types.model().props({
        id: types.string,
        image: types.optional(types.string, ''),
        label: types.string,
        nameId: types.string,
        value: types.string,
        isDeleted: types.boolean,
      })
    ),
    []
  ),
  roles: types.array(types.number),
});

/**
 * User model.
 */
export const UserModel = types.model('User').props({
  id: types.identifier,
  allBusinesses: types.array(types.string),
  avi: types.maybe(types.string),
  initials: types.maybe(types.string),
  device: types.maybe(types.string),
  email: types.string,
  firstName: types.maybe(types.string),
  fullName: types.maybe(types.string),
  isAdmin: types.boolean,
  isAuthenticated: types.maybe(types.boolean),
  isEventOwner: types.maybe(types.boolean),
  isRestrictedAdmin: types.boolean,
  isSuperAdmin: types.boolean,
  isVendorOwner: types.boolean,
  lastActivity: types.union(types.Date, types.string),
  lastLocation: types.optional(
    types.model().props({
      coordinates: types.maybe(types.string),
      directions: types.maybe(types.string),
      latitude: types.maybe(types.number),
      longitude: types.maybe(types.number),
    }),
    {}
  ),
  lastLoginTime: types.union(types.Date, types.string),
  lastName: types.maybe(types.string),
  organizationId: types.optional(types.string, ''),
  phone: types.maybe(types.string),
  postalCode: types.maybe(types.string),
  profiles: types.optional(
    types.array(
      types.model().props({
        id: types.string,
        image: types.optional(types.string, ''),
        label: types.string,
        nameId: types.string,
        value: types.string,
        isDeleted: types.boolean,
      })
    ),
    []
  ),
  roles: types.array(types.number),
  timeCreated: types.union(types.Date, types.string),
  token: types.maybe(
    types.model().props({
      expires: types.string,
      value: types.string,
    })
  ),
  verified: types.maybe(types.boolean),
});

type UserType = Instance<typeof UserModel>;
export type User = UserType;
type UserSnapshotType = SnapshotOut<typeof UserModel>;
export type UserSnapshot = UserSnapshotType;

type UserInfoType = Instance<typeof UserInfoModel>;
export type UserInfo = UserInfoType;

export const createUserDefaultModel = () => types.maybe(UserModel);