import React from 'react';
import { Icon, Text } from '@goodfynd/react-web.lib.ui';
import { StyledFlexBetweenV } from '@goodfynd/react-web.styles';
import pick from 'lodash/pick';
import { observer } from 'mobx-react-lite';

import strings from '../../../config/strings';
import { useCart, useCartDispatch } from '../../../context/cart-context';
import CartTimer from '../../../hooks/useCart/CartTimer';
import Drawer from '../../layout/Drawer';

import { StyledContainer, StyledTotalsContainer } from './styles';
import type { CartProps } from './types';

export const Cart = observer((props: CartProps) => {
  const { cart } = useCart();
  const { renderTotals } = useCartDispatch();
  const { renderEmpty, renderOrder } = useCartDispatch();

  const renderContent = () => {
    if (!cart?.itemTotal) {
      return renderEmpty();
    }

    return (
      <>
        <CartTimer />

        <StyledFlexBetweenV css={{ gap: 24 }}>
          {cart.list?.map((order, index) => renderOrder({ index, order }))}
        </StyledFlexBetweenV>

        <StyledTotalsContainer>
          {renderTotals({
            ...pick(cart, [
              'discount',
              'fees',
              'feesTotal',
              'id',
              'subTotal',
              'tipAmount',
              'total',
            ]),
            showCheckout: true,
          })}
        </StyledTotalsContainer>
      </>
    );
  };

  return (
    <Drawer
      anchor={<Icon badge={{ value: cart?.itemTotal ?? 0 }} name="bag" />}
      position="right"
      anchorCSS={{
        backgroundColor: 'transparent',
        margin: '0 8px',
      }}
      header={
        <Text as="heading5">
          {strings.titles.yourBag}&nbsp;({cart?.itemTotal || 0})
        </Text>
      }
      {...props}
    >
      <StyledContainer>{renderContent()}</StyledContainer>
    </Drawer>
  );
});
