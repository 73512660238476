import React, { useState } from 'react';
import { IconButton } from '@goodfynd/react-web.ui.button';
import { NoSsr } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import { StyledHeader, StyledScrollContent } from './styles';
import type { DrawerProps } from './types';

export default function Drawer({
  anchor,
  anchorCSS,
  children,
  footer,
  header,
  onClose,
  onOpen,
  position,
  showClose = true,
}: DrawerProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (force?: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(force ? force : !open);
    force || !open ? onOpen?.() : onClose?.();
  };

  return (
    <NoSsr>
      <IconButton
        display="compact"
        onClick={toggleDrawer()}
        rank="tertiary"
        style={anchorCSS}
      >
        {anchor}
      </IconButton>

      <MuiDrawer anchor={position} onClose={toggleDrawer()} open={open}>
        {(header || showClose) && (
          <StyledHeader>
            {header}

            {showClose && (
              <IconButton
                display="compact"
                icon="close"
                onClick={toggleDrawer()}
                rank="tertiary"
              />
            )}
          </StyledHeader>
        )}

        <StyledScrollContent>{children}</StyledScrollContent>

        {footer}
      </MuiDrawer>
    </NoSsr>
  );
}
