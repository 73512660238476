import * as stringUtil from '../../utils/string-util';
import { Api } from './api';
import endpoints from './endpoints';

import type { PublicLotResponse } from './types';
import { LotItem } from '../../types/lots';

export default class EventApi {
  private api: Api;

  constructor(api: Api = new Api()) {
    this.api = api;
  }

  async get(id: string): Promise<PublicLotResponse> {
    try {
      const data: LotItem = await this.api.fetch.get(
        stringUtil.replace(endpoints.lots.base, { ':id': id })
      );
      return { success: true, entry: data };
    } catch (error: any) {
      return { success: false, ...error };
    }
  }
}
