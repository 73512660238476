import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';

const ListDisplayType = types.enumeration(['grid', 'list', 'map']);
export type ListDisplay = Instance<typeof ListDisplayType>;

export const SearchStore = types
  .model('SearchStore', {
    display: types.optional(ListDisplayType, 'grid'),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setDisplay: (display: ListDisplay) => {
      self.display = display;
    },
  }));

type SearchStoreType = Instance<typeof SearchStore>;
export type SearchStore = SearchStoreType;
type SearchStoreSnapshotType = SnapshotOut<typeof SearchStore>;
export type SearchStoreSnapshot = SearchStoreSnapshotType;

let _SearchStore: SearchStore;
export const useSearchStore = () => {
  if (!_SearchStore) {
    _SearchStore = SearchStore.create({});
  }

  return _SearchStore;
};
