import Script from 'next/script';

export default function HeadScripts() {
  return (
    <>
      {/* Posthog Script */}
      <Script id="posthog" strategy="beforeInteractive">
        {`function getApiKeys() {
            return {
                segmentKey: '${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}',
                posthogKey: '${process.env.NEXT_PUBLIC_POSTHOG_API_KEY}',
                posthogApiHost: 'https://us.i.posthog.com'
            };
          }

          // Get API keys based on environment
          const { segmentKey, posthogKey, posthogApiHost } = getApiKeys();

          // Segment initialization
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=segmentKey;analytics.SNIPPET_VERSION="4.15.3";
          analytics.load(segmentKey);
          }}();

          // Function to get URL parameters
          function getUrlParams() {
              var params = {};
              var queryString = window.location.search.substring(1);
              var urlParams = new URLSearchParams(queryString);
              urlParams.forEach(function(value, key) {
                  if (!key.startsWith('utm_')) {
                      var camelCaseKey = key.toLowerCase().replace(/_(.)/g, function(match, group1) {
                          return group1.toUpperCase();
                      });
                      params[camelCaseKey] = value;
                  }
              });
              return params;
          }

          // Function to collect properties from attributes
          function collectProperties(element) {
              const properties = {};
              for (let i = 0; i < element.attributes.length; i++) {
                  const attr = element.attributes[i];
                  if (attr.name.startsWith('data-property-')) {
                      properties[toCamelCase(attr.name.slice(14))] = attr.value;
                  }
              }
              return properties;
          }

          // Function to convert to camelCase
          function toCamelCase(str) {
              return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                  if (+match === 0) return "";
                  return index === 0 ? match.toLowerCase() : match.toUpperCase();
              });
          }

          // Function to flatten object
          function flattenObject(obj, parentKey = '', result = {}) {
              for (const key in obj) {
                  if (obj.hasOwnProperty(key)) {
                      const propName = parentKey ? parentKey+'_'+ key : key;
                      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                          flattenObject(obj[key], propName, result);
                      } else {
                          result[propName] = obj[key];
                      }
                  }
              }
              return result;
          }

          // Setup event tracking after DOM is fully loaded
          document.addEventListener('DOMContentLoaded', function() {

              // Handle click events for elements with data-analytics attribute
              document.body.addEventListener('click', function(event) {
                  let target = event.target;
                  while (target && target !== document.body) {
                      if (target.hasAttribute('data-analytics')) {
                          const eventName = target.getAttribute('data-analytics');
                          analytics.track(eventName, flattenObject(collectProperties(target)));
                          break;
                      }
                      target = target.parentElement;
                  }
              });

              // Handle form submissions
              document.body.addEventListener('submit', function(event) {
                  if (event.target.tagName === 'FORM') {
                      event.preventDefault();
                      const form = event.target;
                      const formData = new FormData(form);
                      const properties = collectProperties(form);

                      for (let [key, value] of formData.entries()) {
                          properties[toCamelCase(key)] = value;
                      }

                      properties.formName = form.name || form.id || 'unnamed_form';
                      properties.pageUrl = window.location.href;
                      properties.pageTitle = document.title;

                      const flattenedProperties = flattenObject(properties);

                      if (flattenedProperties.email) {
                          analytics.identify(flattenedProperties.email, flattenedProperties);
                      }

                      const eventName = form.getAttribute('data-track') || "formSubmission";
                      analytics.track(eventName, flattenedProperties);

                      // Optional: form.submit();
                  }
              });
          });

          // PostHog initialization
          !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="init push capture register register_once register_for_session unregister unregister_for_session getFeatureFlag getFeatureFlagPayload isFeatureEnabled reloadFeatureFlags updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures on onFeatureFlags onSessionId getSurveys getActiveMatchingSurveys renderSurvey canRenderSurvey getNextSurveyStep identify setPersonProperties group resetGroups setPersonPropertiesForFlags resetPersonPropertiesForFlags setGroupPropertiesForFlags resetGroupPropertiesForFlags reset get_distinct_id getGroups get_session_id get_session_replay_url alias set_config startSessionRecording stopSessionRecording sessionRecordingStarted loadToolbar get_property getSessionProperty createPersonProfile opt_in_capturing opt_out_capturing has_opted_in_capturing has_opted_out_capturing clear_opt_in_out_capturing debug".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);

          analytics.ready(function() {
              // Get URL parameters
              var urlParams = getUrlParams();

              // Initialize PostHog with updated settings
              posthog.init(posthogKey, {
                  api_host: posthogApiHost,
                  segment: window.analytics,
                  capture_pageview: false,
                  person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
                  loaded: function(posthog) {
                      analytics.page({
                          ...urlParams,
                          posthog_distinct_id: posthog.get_distinct_id()
                      });
                  }
              });
          });

          // Custom event tracking functions
          function trackCustomEvent(eventName, properties) {
              analytics.track(eventName, properties);
          }

          function identifyUser(userId, traits) {
              analytics.identify(userId, traits);
          }`}
      </Script>
      {/* End Posthog Script */}
    </>
  );
}
