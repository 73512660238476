import config from '../config';
import strings from '../config/strings';
import dateVendor from './date-util-vendor';

export const addBusinessDays = (
  date: Date | string,
  days: number,
  useStartOfDay = false
) =>
  useStartOfDay
    ? dateVendor.addBusinessDays(startOfDay(date), days)
    : dateVendor.addBusinessDays(new Date(date), days);

export const addDays = (
  date: Date | string,
  days: number,
  useStartOfDay = false
) =>
  useStartOfDay
    ? dateVendor.addDays(startOfDay(date), days)
    : dateVendor.addDays(new Date(date), days);

export const addHours = (
  date: Date | string,
  hours: number,
  useStartOfDay = false
) =>
  useStartOfDay
    ? dateVendor.addHours(startOfDay(date), hours)
    : dateVendor.addHours(new Date(date), hours);

export const addMinutes = (
  date: Date | string,
  minutes: number,
  useStartOfDay = false
) =>
  useStartOfDay
    ? dateVendor.addMinutes(startOfDay(date), minutes)
    : dateVendor.addMinutes(new Date(date), minutes);

export const createTime = (
  hours: number,
  minutes = 0,
  seconds = 0,
  date?: Date
): Date => {
  date = date || new Date();
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    minutes,
    seconds
  );
};

export const differenceInDays = (
  dateLeft: Date | string,
  dateRight: Date | string
) => dateVendor.differenceInDays(new Date(dateLeft), new Date(dateRight));

export const differenceInMinutes = (
  dateLeft: Date | string,
  dateRight: Date | string
) => dateVendor.differenceInMinutes(new Date(dateLeft), new Date(dateRight));

export const endOfDay = (date: Date | string) =>
  dateVendor.endOfDay(new Date(date));

export const endOfMonth = (date: Date | string) =>
  dateVendor.endOfMonth(new Date(date));

export const endOfWeek = (date: Date | string) =>
  dateVendor.endOfWeek(new Date(date));

export const endOfYear = (date: Date | string) =>
  dateVendor.endOfYear(new Date(date));

export const format = (date: Date | string, format: string) => {
  return dateVendor.format(new Date(date), format);
};

export const getDate = (date: Date | string) =>
  dateVendor.getDate(new Date(date));

export const getDayDisplay = (
  date: Date | string,
  formatString = 'MM/dd/yyyy'
) => {
  if (isToday(date)) {
    return strings.labels.today;
  }

  if (isTomorrow(date)) {
    return strings.labels.tomorrow;
  }

  if (isYesterday(date)) {
    return strings.labels.yesterday;
  }

  return format(date, formatString);
};

export const getHours = (date: Date | string) =>
  dateVendor.getHours(new Date(date));

export const getMinutes = (date: Date | string) =>
  dateVendor.getMinutes(new Date(date));

export const getTimeDisplay = (
  date: Date | string,
  addSpace = true,
  showMinutes = false
) => {
  date = new Date(date);
  return format(
    date,
    showMinutes || date.getMinutes() > 0
      ? addSpace
        ? config.dateFormats.timeSpace
        : config.dateFormats.time
      : config.dateFormats.hourM
  );
};

export const isSameDay = (dateLeft: Date | string, dateRight: Date | string) =>
  dateVendor.isSameDay(new Date(dateLeft), new Date(dateRight));

export const isToday = (date: Date | string) =>
  dateVendor.isToday(new Date(date));

export const isTomorrow = (date: Date | string) =>
  dateVendor.isTomorrow(new Date(date));

export const isValid = (date: Date) =>
  date instanceof Date && !isNaN(date.getTime());

export const isYesterday = (date: Date | string) =>
  dateVendor.isYesterday(new Date(date));

export const overlaps = (left: Interval, right: Interval) =>
  dateVendor.overlaps(left, right);

export const removeTime = (date: Date | string): Date => {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const setDate = (date: Date | string, dayOfMonth: number) =>
  dateVendor.setDate(new Date(date), dayOfMonth);

export const setTime = (
  date: Date | string,
  time: Date,
  useSeconds = false
): Date => {
  const update = new Date(date);
  update.setHours(time.getHours());
  update.setMinutes(time.getMinutes());
  update.setSeconds(useSeconds ? time.getSeconds() : 0);
  return update;
};

export const startOfDay = (date: Date | string) =>
  dateVendor.startOfDay(new Date(date));

export const startOfMonth = (date: Date | string) =>
  dateVendor.startOfMonth(new Date(date));

export const startOfWeek = (date: Date | string) =>
  dateVendor.startOfWeek(new Date(date));

export const startOfYear = (date: Date | string) =>
  dateVendor.startOfYear(new Date(date));
