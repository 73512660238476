import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getGeocode } from 'use-places-autocomplete';

import config from '../config';
import { useUserApi } from '../hooks/api';
import * as storageUtil from '../utils/storage-util';

export interface LocationOptions {
  defaultLocation?: {
    position: JsonLocation;
  };
  reverseGeocode?: boolean;
  store?: boolean;
}

export default function useIPLocation({
  defaultLocation,
  reverseGeocode,
  store = true,
}: LocationOptions = {}) {
  const userApi = useUserApi();
  const [address, setAddress] = useState<PlaceAddress>();
  const [busy, toggleBusy] = useState(true);
  const [error, setError] = useState<any>();
  const [position, setPosition] = useState<Coordinates | undefined>(
    defaultLocation?.position
  );

  const { isLoading } = useQuery<Coordinates, unknown>(
    config.queryKeys.userLocation,
    () => {
      return userApi.getIPLocation();
    },
    {
      enabled: !!!defaultLocation,
      onError: (error) => {
        setError(error);
        setPosition(config.defaultLocation);
      },
      onSuccess: (data) => {
        setPosition(data);
      },
    }
  );

  const handleSuccess = useCallback(
    async (coords: Coordinates) => {
      toggleBusy(true);

      const { latitude, longitude } = coords;
      let placeAddress: PlaceAddress | undefined;

      try {
        if (reverseGeocode) {
          // TODO: Add geocoding support
          const location = { lat: latitude, lng: longitude };
          const results = await getGeocode({ location });
          if (results?.length > 0) {
            const result = results[0];
            placeAddress = {
              id: result.place_id,
              label: result.formatted_address,
            };
            setAddress(placeAddress);
          }
        }
      } catch (error) {
        console.warn(error);
      } finally {
        if (store) {
          storageUtil.local.set(config.storageKeys.userLocation, {
            ...coords,
            placeAddress,
            coordinates: `${latitude}, ${longitude}`,
          });
        }

        toggleBusy(false);
      }
    },
    [reverseGeocode, store]
  );

  useEffect(() => {
    if (position) {
      handleSuccess(position);
    }
  }, [handleSuccess, position]);

  return {
    address,
    busy: busy || isLoading,
    error,
    handleSuccess,
    position,
    setAddress,
    setPosition,
  };
}
