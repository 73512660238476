import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import * as stringUtil from '../../utils/string-util';

import type { LotItem } from '../../types/lots';
import type { SearchRequest } from '../../types/search';
import type {
  EventSearchResponse,
  LotEventsResponse,
  MetaResponse,
  PublicLotResponse,
} from '../../services/api/types';
export const useEventApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async get(id: string): Promise<PublicLotResponse> {
        try {
          const data: LotItem = await api.get(
            stringUtil.replace(endpoints.lots.base, { ':id': id })
          );
          return { success: true, entry: data };
        } catch (error: any) {
          return { success: false, ...error };
        }
      },

      async getByLot(lotId: string): Promise<LotEventsResponse> {
        try {
          const data: LotEventsResponse = await api.get(
            stringUtil.replace(endpoints.events.byLot, { ':lotId': lotId })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async search(query: SearchRequest): Promise<EventSearchResponse> {
        try {
          const data: EventSearchResponse = await api.get(
            endpoints.events.search,
            {
              rows: 24,
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return { success: true, ...data };
        } catch (error: any) {
          return { success: false, ...error };
        }
      },
    };
  }, [api]);
};
