import React, { useCallback, useEffect, useState } from 'react';
import type { DehydratedState } from 'react-query';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Auth0Provider } from '@auth0/auth0-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import type { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import { GoogleApiProvider } from '@goodfynd/react-web.context.google-api-context';
import { SnackbarProvider } from '@goodfynd/react-web.context.snackbar-context';
import { createEmotionCache, ThemeProvider } from '@goodfynd/react-web.theme';
import CssBaseline from '@mui/material/CssBaseline';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Loading } from '../components';
import Layout from '../components/layout/Layout';
import { StyledFlexCenter } from '../components/styles';
import config from '../config';
import env from '../config/env';
import routes, { driftRoutes } from '../config/routes';
import strings from '../config/strings';
import { AppProvider } from '../context/app-context';
import { LocationProvider } from '../context/location-context';
import type { RootStore } from '../stores';
import { DefaultState, useRootStore } from '../stores';
import { RootStoreProvider } from '../stores/root-store/root-store-context';
import { useMobileSize } from '../utils/base';

import '@goodfynd/react-web.theme/fonts/fonts.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '@goodfynd/react-web.design-system/css-vars/themes.css';
import './global.css';

Bugsnag.start({
  apiKey: config.env.BUGSNAG_API_KEY,
  enabledReleaseStages: [config.env.NAME.toLowerCase()],
  plugins: [new BugsnagPluginReact()],
  releaseStage: config.env.NAME.toLowerCase(),
});
BugsnagPerformance.start({ apiKey: config.env.BUGSNAG_API_KEY });

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import HeadScripts from './_head-scripts';
if (config.app.mode.isProduction) {
  console.debug = () => console.info();
  console.error = () => console.info();
  console.log = () => console.info();
  console.warn = () => console.info();
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(
  React
) as BugsnagErrorBoundary;

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps<{ dehydratedState: DehydratedState }> & {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
};

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { dehydratedState, ...pageProps },
}: AppPropsWithLayout) {
  const [enRoute, toggleRouting] = useState(false);
  const router = useRouter();

  const {
    query: { address = '', latitude, longitude },
  } = useRouter();

  const createRootStore = useRootStore();
  useEffect(() => {
    (async () => {
      setRootStore(await createRootStore()); // configure up mobx-state-tree
      // Now wait for state tree to be ready
    })();
  }, [createRootStore]);

  const { drift } = global.window || {};
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      })
  );

  const [rootStore, setRootStore] = useState<RootStore>(DefaultState);

  const isMobile = useMobileSize();
  const handleRouteChange = useCallback(() => {
    toggleRouting(false);

    if (
      JSON.parse(process.env.NEXT_PUBLIC_MAINTENANCE_MODE || 'false') &&
      !router.pathname.endsWith(routes.maintenance)
    ) {
      window.location.href = routes.maintenance;
      return;
    }
  }, [router.pathname]);

  const scrollWatch = useCallback(
    function () {
      drift &&
        drift.on('ready', (api: any) => {
          if (driftRoutes.includes(location.pathname)) {
            setTimeout(() => {
              api.startInteraction({
                interactionId: config.env.DRIFT_INTERACTION_ID,
              });
            }, 3000);

            document.removeEventListener('scroll', scrollWatch);
          }
        });
    },
    [drift]
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeStart', () => toggleRouting(true));

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [handleRouteChange, router.events]);

  useEffect(() => {
    if (isMobile && global.window) {
      document.removeEventListener('scroll', scrollWatch);
    } else {
      document.addEventListener('scroll', scrollWatch);
    }

    return () => {
      document.removeEventListener('scroll', scrollWatch);
    };
  }, [isMobile, scrollWatch]);

  const onRedirectCallback = (appState: any) => {
    router.replace(appState?.returnTo || '/');
  };

  // Wait for state to be ready
  if (rootStore) {
    const getLayout =
      Component.getLayout ?? ((page) => <Layout>{page}</Layout>);
    return (
      <>
        <ErrorBoundary>
          <ThemeProvider>
            <Auth0Provider
              clientId={process.env.NEXT_PUBLIC_CLIENT_ID || ''}
              domain={process.env.NEXT_PUBLIC_DOMAIN || ''}
              onRedirectCallback={onRedirectCallback}
              useRefreshTokens={true}
              useRefreshTokensFallback={true}
              authorizationParams={{
                scope: 'openid email offline_access',
                audience: process.env.NEXT_PUBLIC_AUDIENCE,
                redirect_uri:
                  (typeof window !== 'undefined' &&
                    window.location.origin + routes.account.callback) ||
                  '',
              }}
            >
              <RootStoreProvider value={rootStore}>
                <QueryClientProvider client={queryClient}>
                  <Hydrate state={dehydratedState}>
                    <CacheProvider value={emotionCache}>
                      <Head>
                        <title>{strings.app.name}</title>
                        <meta
                          content="initial-scale=1, width=device-width"
                          name="viewport"
                        />
                      </Head>
                      <GoogleApiProvider
                        apiKey={env.GOOGLE_API_KEY}
                        blocking={global.window?.location.host.startsWith(
                          'embed.'
                        )}
                      >
                        <LocationProvider
                          defaultLocation={
                            typeof latitude === 'string' &&
                            typeof longitude === 'string'
                              ? {
                                  position: {
                                    address: address as string,
                                    coordinates: `${latitude},${longitude}`,
                                    latitude: Number(latitude),
                                    longitude: Number(longitude),
                                  },
                                }
                              : undefined
                          }
                        >
                          <SnackbarProvider>
                            <AppProvider>
                              {getLayout(
                                <>
                                  <CssBaseline />

                                  {enRoute ? (
                                    <StyledFlexCenter>
                                      <Loading />
                                    </StyledFlexCenter>
                                  ) : (
                                    <Component {...pageProps} />
                                  )}
                                </>
                              )}
                            </AppProvider>
                          </SnackbarProvider>
                        </LocationProvider>
                      </GoogleApiProvider>
                    </CacheProvider>
                  </Hydrate>

                  {!config.app.mode.isProduction && <ReactQueryDevtools />}
                </QueryClientProvider>
              </RootStoreProvider>
            </Auth0Provider>
          </ThemeProvider>
        </ErrorBoundary>
        <HeadScripts />
      </>
    );
  }

  return null;
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// export async function getServerSideProps(context) {
//   return {
//     props: {
//       name: 'Lemaire S.',
//     }, // will be passed to the page component as props
//   };
// }

export default MyApp;
