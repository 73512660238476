import React from 'react';
import { LineItem } from '@goodfynd/react-web.checkout.line-item';

import type { RemoveItemRequest } from '../../types/orders';

import type { OrderItemRowProps } from './types';

export default function OrderItemRow({
  border,
  item,
  order,
  removeItem,
  ...props
}: OrderItemRowProps): JSX.Element {
  const handleRemove = async () => {
    const productId = item.productId;
    const request: RemoveItemRequest = {
      id: item.id,
      orderId: order.id,
      ...item.price,
    };
    removeItem(productId, request);
  };

  return (
    <LineItem
      {...props}
      addBorder={border}
      onRemove={order.paymentStatus ? undefined : handleRemove}
      item={{
        ...item,
        url: `/vendors/${order.merchant.nameId}/products/${item.productId}`,
      }}
    />
  );
}
