import {
  DarkColors,
  DefaultColors,
  useScreenDesktop,
  useScreenPhone,
  useScreenTabletMax,
} from '@goodfynd/react-web.theme';
import type { ColorName } from '@goodfynd/react-web.ui.icon';

import config from '../config';

import * as storageUtil from './storage-util';

export function formatDate(t: Date): string {
  return `${padNum(t.getUTCHours())}:${padNum(t.getUTCMinutes())}:${padNum(
    t.getUTCSeconds()
  )}:${padMiliseconds(t.getUTCMilliseconds())}`;
}

export function getThemeColor(
  colorKey: ColorName,
  isDarkMode: boolean
): string {
  return isDarkMode ? DarkColors[colorKey] : DefaultColors[colorKey];
}

export function getScript(scriptUrl: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.onload = () => resolve(true);
    script.onerror = () =>
      reject(new Error(`failed to load script...${scriptUrl}`));
    document.body.appendChild(script);
  });
}

export const isIOS =
  typeof navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export const useMobileSize = useScreenPhone;
export const useTabletSize = useScreenTabletMax;
export const useScreenMdSize = useScreenTabletMax;
export const useScreenLgSize = useScreenDesktop;

export function padNum(n: number): string | number {
  return n < 10 ? `0${n}` : n;
}

export function padMiliseconds(n: number): string | number {
  return n < 10 ? `00${n}` : n < 100 ? `0${n}` : n;
}

export function scrollToTop(): void {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function setPageTitle(title: string): string {
  return `${title} | Goodfynd`;
}

export function redirectTo(dir: Page): void {
  window.location.href = dir;
}

export function resetAppState(): void {
  storageUtil.local.remove(config.storageKeys.guestOrder);
  storageUtil.local.remove(config.storageKeys.rootState);
  storageUtil.local.remove(config.storageKeys.userLocation);
}

export const urlHistoryReplace = (
  params: Dictionary<string, string | undefined | null>
): void => {
  const url = new URL(window.location.href);
  Object.keys(params).forEach(
    (key) => params[key] && url.searchParams.set(key, params[key] as string)
  );
  window.history.replaceState(null, '', url.toString());
};

export const waitSeconds = async (seconds: number) =>
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 1000 * seconds);
  });

export enum Page {
  Home = '/',
  Search = '/search',
  Profile = '/profile',
  // ... easy page names
}

export function delay(time: number): Promise<unknown> {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}
