import queryString from 'query-string';

export const get = (key: string, search?: string) => {
  let value = '';
  if (global.window) {
    const params = queryString.parse(search || window.location.search);
    const param = params[key];
    value =
      typeof param === 'string' ? param : JSON.stringify(params[key] || '');
  }

  return value;
};

export const getParsed = (key: string) => {
  return JSON.parse(get(key));
};

export const parse = (query: string = window.location.search) =>
  queryString.parse(query);

export const toString = (obj: object) => {
  return queryString.stringify(obj);
};

export const toUrl = (url: string, query: Record<string, any>) => {
  return queryString.stringifyUrl({
    url,
    query,
  });
};
