export enum UserRole {
  Admin = 0,
  VendorOwner = 1,
  User = 2,
  LotOwner = 3,
  SuperAdmin = 4,
  Intern = 5,
  VendorManager = 6,
  VendorEmployee = 7,
  OrganizationOwner = 8,
  OrganizationManager = 9,
  OrganizationAccountant = 10,
}
