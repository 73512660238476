import React from 'react';
import { CartContext, CartDispatchContext } from './CartContext';

export function useCart() {
  const context = React.useContext(CartContext);
  if (context === undefined) {
    throw new Error(
      'The useLocation hook must be used within a LocationProvider.'
    );
  }
  return context;
}

export function useCartDispatch() {
  const context = React.useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error(
      'The useLocationDispatch hook must be used within a LocationProvider.'
    );
  }
  return context;
}
