import { styled } from '@goodfynd/react-web.theme';

export const StyledScrollContent = styled('div', {
  overflowY: 'auto',
});

export const StyledHeader = styled('div', {
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 16,
});
