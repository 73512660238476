import numbro from 'numbro';

export const asCurrency = (value: number) => parseFloat(value.toFixed(2));

export const getTicketNumber = (time?: Date) => {
  time = new Date(time || new Date().toString());
  const timeString = time.getTime().toString();
  const timeStringLength = timeString.length;
  return parseInt(
    timeString.substr(0, timeStringLength - 3).substr(timeStringLength - 3 - 3),
    10
  );
};

export const minutesToMS = (minutes: number) => minutes * 60000;

export const random = (min = 1, max = 10) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const renderPrice = (
  price: number | string,
  symbol: '$' | '¥' | '@' | false = '$'
) => {
  return numbro(price).format({
    currencySymbol: symbol || '',
    mantissa: 2,
    output: symbol ? 'currency' : 'number',
    spaceSeparated: false,
  });
};
