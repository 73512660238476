import { observer } from 'mobx-react-lite';
import React, { createContext, useMemo } from 'react';

import { useGoogleApi } from '@goodfynd/react-web.context.google-api-context';

import useIPLocation from '../../hooks/useIPLocation';

import type {
  LocationContextValue,
  LocationDispatchContextValue,
  LocationProviderProps,
} from './types';
export const LocationContext = createContext<LocationContextValue | undefined>(
  undefined
);
export const LocationDispatchContext = createContext<
  LocationDispatchContextValue | undefined
>(undefined);

export default observer(function LocationProvider({
  children,
  defaultLocation,
}: LocationProviderProps) {
  const { isLoaded } = useGoogleApi();

  const { address, busy, error, position, setAddress, setPosition } =
    useIPLocation({ defaultLocation, reverseGeocode: isLoaded });

  const value = useMemo<LocationContextValue>(() => {
    return {
      address,
      busy,
      error,
      isLoaded,
      position,
    };
  }, [address, busy, error, isLoaded, position]);

  return (
    <LocationContext.Provider value={value}>
      <LocationDispatchContext.Provider
        value={{
          setAddress,
          setPosition,
        }}
      >
        {children}
      </LocationDispatchContext.Provider>
    </LocationContext.Provider>
  );
});
