import React, { useEffect, useMemo } from 'react';
import { useTimer } from 'react-timer-hook';
import { Icon, Text } from '@goodfynd/react-web.lib.ui';
import type { CSS } from '@stitches/react';

import strings from '../../config/strings';
import { useCart, useCartDispatch } from '../../context/cart-context';

import { StyledTimer } from './styles';

const CartTimer = ({ css }: { css?: CSS }) => {
  const { cart } = useCart();
  const { refreshCart } = useCartDispatch();
  const currentTime = useMemo(() => new Date(), []);
  const expireTime = useMemo(
    () =>
      cart?.list
        .map((order) => order.expireTime && new Date(order.expireTime))
        .sort()[0] ?? currentTime,
    [cart?.list, currentTime]
  );

  const { isRunning, minutes, seconds, restart } = useTimer({
    expiryTimestamp: expireTime || currentTime,
    onExpire: () => console.warn('onExpire called'),
  });

  useEffect(() => {
    if (expireTime && expireTime > currentTime) {
      restart(expireTime);
    }
  }, [currentTime, expireTime, restart]);

  useEffect(() => {
    if (expireTime && !isRunning) {
      refreshCart();
    }
  }, [expireTime, isRunning, refreshCart]);

  return useMemo(
    () => (
      <StyledTimer css={css}>
        {!isRunning && <Icon color="TextIconInteractiveBlack" name="info" />}

        <Text as="body2" inherit style={{ marginLeft: 4 }}>
          {seconds > 0
            ? `${minutes.toString().padStart(2, '0')}:${seconds
                .toString()
                .padStart(2, '0')} ${strings.labels.beforePickupExpires}`
            : strings.labels.pickupTimeExpired}
        </Text>
      </StyledTimer>
    ),
    [css, isRunning, minutes, seconds]
  );
};

export default CartTimer;
