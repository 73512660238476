import React, { useCallback } from 'react';
import { Icon, Link, Logo } from '@goodfynd/react-web.lib.ui';
import NoSsr from '@mui/material/NoSsr';

import config from '../../../config';
import FooterLegal from '../FooterLegal';

import FooterLinkList from './FooterLinkList/FooterLinkList';
import { FOOTER_NAV, SOCIALS } from './footer-links';
import {
  StyledFooter,
  StyledFooterContainer,
  StyledLegalContainer,
  StyledLinkListWrapper,
  StyledSocialContainer,
  StyledTopRow,
} from './styles';

export default function Footer({ showNav = true }: FooterProps): JSX.Element {
  const renderSocialLinks = useCallback(
    (position: 'bottom' | 'right' = 'right') => (
      <StyledSocialContainer position={position}>
        {SOCIALS.map((social) => (
          <li key={social.icon}>
            <Link color="TextIconInteractiveWhite" href={social.href || ''}>
              <Icon
                color="TextIconInteractiveWhite"
                name={social.icon}
                size={24}
              />
            </Link>
          </li>
        ))}
      </StyledSocialContainer>
    ),
    []
  );

  return (
    <StyledFooter>
      <NoSsr>
        <StyledFooterContainer position="footer">
          <StyledTopRow>
            <Logo environment={config.env.NAME_LOWER} inverted unoptimized />

            {renderSocialLinks()}
          </StyledTopRow>

          {showNav && (
            <StyledLinkListWrapper>
              {FOOTER_NAV.map((section) => (
                <FooterLinkList
                  key={section.name}
                  links={section.links}
                  sectionName={section.name}
                />
              ))}
            </StyledLinkListWrapper>
          )}

          {renderSocialLinks('bottom')}
        </StyledFooterContainer>

        <StyledLegalContainer>
          <FooterLegal />
        </StyledLegalContainer>
      </NoSsr>
    </StyledFooter>
  );
}
