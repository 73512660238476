import { useEffect, useState } from 'react';

import { ShareContent } from './types';

export function useWebShare(onSuccess?: () => void, onError?: () => void) {
  const [busy, setBusy] = useState(true);
  const [isSupported, toggleSupport] = useState(false);

  useEffect(() => {
    if (!!navigator.share) {
      toggleSupport(true);
    } else {
      toggleSupport(false);
    }

    setBusy(false);
  }, [onSuccess, onError]);

  const share = ({ text, title, url = window.location.href }: ShareContent) => {
    navigator
      .share({ text, title, url })
      .then(() => onSuccess?.())
      .catch(() => onError?.());
  };

  return {
    busy,
    isSupported,
    share,
  };
}
