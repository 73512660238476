import { cast, destroy, Instance, SnapshotOut, types } from 'mobx-state-tree';

import config from '../../config';
import { OrderingOption } from '../../types/products';
import { VendorItem } from '../../types/shared';
import * as storageUtil from '../../utils/storage-util';
import { withEnvironment } from '../extensions/with-environment';
import { CustomerModel, CustomerSnapshot } from '../models/customer';
import {
  OrderRequestModel,
  OrderRequestSnapshot,
} from '../models/order-request';
import { OrderEventModel } from '../models/order-request/order-event';

export const OrderStore = types
  .model('OrderStore', {
    busy: types.optional(types.boolean, false),
    customer: types.maybe(CustomerModel),
    event: types.maybe(OrderEventModel),
    productId: types.maybe(types.string),
    request: types.maybe(OrderRequestModel),
    vendorId: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    removeCard: (id: string) => {
      if (self.customer?.creditCards) {
        self.customer.creditCards = cast(
          self.customer.creditCards.filter((card) => card.id !== id)
        );
      }
    },
    resetOrder: () => {
      self.busy = false;
      self.event && destroy(self.event);
      self.productId = undefined;
      self.request && destroy(self.request);
      self.vendorId = undefined;

      storageUtil.local.remove(config.storageKeys.guestOrder);
    },
    setBusy: (busy = true) => {
      self.busy = busy;
    },
    setCustomer: (customer: CustomerSnapshot) => {
      self.customer = cast(customer);
    },
    updated: () => {
      self.productId = undefined;
    },
  }))
  .actions((self) => ({
    getOptionsTotal: () => {
      return (
        self.request?.optionGroups
          .map((group) =>
            group.options
              .map((option) => option.price || 0)
              .reduce((a, b) => a + b, 0)
          )
          .reduce((a, b) => a + b, 0) || 0
      );
    },
    resetPickupOptions: (vendorId: string) => {
      if (self.vendorId !== vendorId) {
        console.debug('Set Pickup: ', self.vendorId, vendorId);
        self.vendorId = vendorId;
        self.event && destroy(self.event);
        self.request && destroy(self.request);
      }
    },
    setBusy: (busy = true) => {
      self.busy = busy;
    },
    startOrder: (productId: string, request: OrderRequestSnapshot) => {
      if (self.productId !== productId || !self.request) {
        console.debug('Start Order: ', self.productId, productId);
        self.productId = productId;
        self.request = cast({
          ...request,
          ...self.event,
          orderId: storageUtil.local.get(config.storageKeys.guestOrder),
          productId,
        });
      }
    },
    updateEvent: (
      vendor: VendorItem,
      entry: OperatingDayLocation,
      pickupTime: Date
    ) => {
      self.vendorId = vendor.id;
      self.event = {
        pickupTime: new Date(pickupTime),
        pickupLocationName: entry.location.name,
        pickupTimeEnd: new Date(entry.end),
        pickupTimeStart: new Date(entry.start),
        scheduleId: entry.id,
      };
    },
    updateOptions: (groupId: string, options: OrderingOption[]) => {
      if (self.request) {
        self.request = cast({
          ...self.request,
          optionGroups: self.request.optionGroups.map((group) => {
            if (group.id === groupId) {
              return {
                ...group,
                options,
              };
            }

            return group;
          }),
        });
      }
    },
    updateSpecialRequests: (requests: string) => {
      if (self.request) {
        self.request = cast({
          ...self.request,
          requests,
        });
      }
    },
  }));

type OrderStoreType = Instance<typeof OrderStore>;
export type OrderStore = OrderStoreType;
type OrderStoreSnapshotType = SnapshotOut<typeof OrderStore>;
export type OrderStoreSnapshot = OrderStoreSnapshotType;

let _orderStore: OrderStore;
export const useOrderStore = () => {
  if (!_orderStore) {
    _orderStore = OrderStore.create({});
  }

  return _orderStore;
};
