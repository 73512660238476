import React, { useCallback, useState } from 'react';
import { Button, IconButton } from '@goodfynd/react-web.lib.ui';

import { useLikeApi } from '../../hooks/api';

import type { LikeButtonProps } from './types';

export default function LikeButton({
  buttonStyle = 'default',
  count,
  item,
  liked,
  type,
  ...props
}: LikeButtonProps) {
  const likeApi = useLikeApi();
  const [busy, setBusy] = useState(false);
  const [isLiked, toggleLiked] = useState(liked);
  const [likes, setLikes] = useState(count);

  const handleClick = useCallback(async () => {
    try {
      setBusy(true);

      const toggleLike = async (): Promise<{
        likes: number;
        liked: boolean;
      }> => {
        const entityType = type === 'event' ? 'lot' : type;

        await likeApi.toggleLike({
          entityId: item.id,
          entityType: entityType,
          isLiked: isLiked,
        });
        return { likes: isLiked ? likes - 1 : likes + 1, liked: !isLiked };
      };

      const toggleResult = await toggleLike();
      setLikes(toggleResult.likes);
      toggleLiked(toggleResult.liked);
    } catch (error) {
      console.warn(error);
    } finally {
      setBusy(false);
    }
  }, [isLiked, item.id, likeApi, likes, type]);

  const commonProps = {
    disabled: busy,
    onClick: handleClick,
    ...props,
  };

  const iconName = isLiked ? 'heart_filled' : 'heart';
  switch (buttonStyle) {
    case 'default':
      return (
        <Button
          busy={busy}
          color="neutral"
          iconLeft={iconName}
          size="small"
          {...commonProps}
        >
          {likes > 0 && <span>&nbsp;{likes}</span>}
        </Button>
      );

    case 'icon':
      return (
        <IconButton
          busy={busy}
          icon={iconName}
          rank="tertiary"
          {...commonProps}
        />
      );
  }
}
