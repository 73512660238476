import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * Order Event model.
 */
export const OrderEventModel = types.model('OrderEvent', {
  pickupLocationName: types.maybe(types.string),
  pickupTime: types.Date,
  pickupTimeEnd: types.Date,
  pickupTimeStart: types.Date,
  scheduleId: types.string,
});

type OrderEventType = Instance<typeof OrderEventModel>;
export interface OrderEvent extends OrderEventType {}
type OrderEventSnapshotType = SnapshotOut<typeof OrderEventModel>;
export interface OrderEventSnapshot extends OrderEventSnapshotType {}
export const createOrderDefaultModel = () => types.maybe(OrderEventModel);
