import { CartOrder } from '../../types/cart';
import { stringUtil } from '../../utils';
import { Api } from './api';
import endpoints from './endpoints';

import type { OrderResponse } from './types';

export enum OrderSort {
  PickupTimeAsc = '1',
  PickupTimeDesc = '2',
  TimeCreatedAsc = '3',
  TimeCreatedDesc = '4',
  DueDateAsc = '5',
  DueDateDesc = '6',
  TimeCompletedAsc = '7',
  TimeCompletedDesc = '8',
}

export default class OrderApi {
  private api: Api;

  constructor(api: Api = new Api()) {
    this.api = api;
  }

  async addInvoiceTip(id: string, tip: number): Promise<OrderResponse> {
    try {
      const data: OrderResponse = await this.api.fetch.post(
        stringUtil.replaceAll(endpoints.invoices.tip, {
          ':id': id,
          ':tip': tip.toString(),
        })
      );

      return data;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  async finalizeInvoice(id: string, tip: number): Promise<OrderResponse> {
    try {
      const data: OrderResponse = await this.api.fetch.post(
        stringUtil.replaceAll(endpoints.invoices.finalize, {
          ':id': id,
          ':tip': tip.toString(),
        })
      );

      return data;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  async getOrder(id: string): Promise<OrderResponse> {
    try {
      const order: CartOrder = await this.api.fetch.get(
        stringUtil.replace(endpoints.orders.base, {
          ':id': id,
        })
      );

      return {
        order,
        success: true,
      };
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}
