import { Accordion } from '@mui/material';

import { styled } from '../../../theme/stitches.config';

export const StyledAccordion = styled(Accordion, {
  marginTop: 16,
  '+ .MuiAccordion-root': {
    marginTop: 0,
  },
});

export const StyledImageContainer = styled('div', {
  height: 248,
});

export const StyledMapContainer = styled('div', {
  alignItems: 'center',
  backgroundColor: '$GreenLightest',
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'center',
  marginTop: 8,
  maxHeight: 150,
  overflow: 'hidden',
  '> img': {
    width: '100%',
  },
});

export const StyledModalContent = styled('div', {
  padding: 24,
  paddingBottom: 112,
  '@sm': {
    padding: 16,
    paddingBottom: 88,
  },
});
