import React, { useCallback, useEffect, useState } from 'react';

import CmsApi from '../../../services/api/cms-api';
import renderCmsBodyComponent from '../../../utils/cms-util/renderCmsBodyComponent';

import type { CmsCollectionProps } from './types';
export default function CmsCollection({
  fieldName,
  fieldValue,
  slug,
}: CmsCollectionProps): JSX.Element {
  const [body, setBody] = useState<any[]>([]);
  const [busy, toggleBusy] = useState(true);

  useEffect(() => {
    if (fieldName && fieldValue && slug) {
      (async () => {
        try {
          toggleBusy(true);
          const api = new CmsApi();
          const fields = await api.getCollectionData(
            slug,
            fieldName,
            fieldValue
          );
          setBody([{ fields, type: 'footer' }]);
        } finally {
          toggleBusy(false);
        }
      })();
    }
  }, [fieldName, fieldValue, slug]);

  const renderBody = useCallback(
    () => (
      <>
        {body?.map(({ fields, type }, index) =>
          renderCmsBodyComponent(index, type, fields)
        )}
      </>
    ),
    [body]
  );

  return busy ? <></> : renderBody();
}
