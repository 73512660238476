import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

export const useUserApi = () => {
  const api = useFetch({ baseURL: '' });
  return useMemo(() => {
    return {
      async getIPLocation(): Promise<Coordinates> {
        try {
          const data: any = await api.get(endpoints.locations.byIP);
          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
