import LocalizedStrings from 'react-localization';

import { emojis, unicode } from './';

const strings = new LocalizedStrings({
  en: {
    alerts: {
      noOrders: `Sorry, you don't have any orders yet`,
      noVendors: `No vendors found.`,
    },
    app: {
      name: 'Goodfynd',
      nameLegal: 'Goodfynd, Inc.',
    },
    errors: {
      productLoading: 'Error loading product. Please try again.',
      productUnknown: 'Unknown product. Please try again.',
      tryAgain: 'An error occurred. Please try again.',
    },
    labels: {
      accept: 'Accept',
      add: 'Add',
      addCreditCard: 'Add Credit Card',
      addYourTruck: 'Add Your Truck',
      allTrucks: 'All Trucks',
      alreadyMember: 'Already have an account',
      apply: 'Apply',
      authorized: `Authorized! ${emojis.happy}`,
      bagEmpty: `Your Bag's Empty`,
      beforePickupExpires: 'before pickup time slot is removed',
      bookATruck: 'Book a Truck',
      bookTruckToday: 'Book a Food Truck Today',
      cancel: 'Cancel',
      canceled: 'Canceled',
      cancelOrder: 'Cancel Order',
      cash: 'Cash',
      changePickupTime: 'Change Pickup Time',
      choosePickupTime: 'Choose Pickup Time',
      comingSoon: 'Coming Soon!',
      completed: 'Completed',
      confirmed: 'Confirmed!',
      continue: 'Continue',
      continueToPayment: 'Continue to Payment',
      continueWithEmail: 'or continue with email',
      copied: 'Copied!',
      copiedToClipboard: 'Copied to clipboard!',
      createEvent: 'Create an Event',
      currentPickupLocation: 'Current Pickup Location',
      pickupLocation: 'Pickup Location',
      customTip: 'Custom Tip',
      darkMode: 'Night Mode',
      date: 'Date',
      dindinTime: `Din' Din' time`,
      dinnerTime: 'Dinner time',
      discount: 'Discount',
      email: 'Email Address',
      enterPromoCode: 'Enter Promo Code',
      eSign: 'E-Sign\u00A0Consent',
      events: 'Events',
      favorite: 'Favorite',
      favorites: 'Favorites',
      feelingHungry: 'Feeling hungry',
      findFood: 'Find Food',
      findMoreFood: 'Find More Food',
      findTrucks: 'Find Trucks',
      firstName: 'First Name',
      forgotPassword: 'Forgot Password',
      forgotPasswordSub:
        'Enter your email or username below and we’ll send you a password reset link',
      getDirections: 'Get Directions',
      homeGeoPlaceholder: 'Set your location',
      homeSearchPlaceholder: 'Search trucks, food items, events...',
      hungry: 'Hungry',
      inProgress: 'In-Progress',
      itemNotAvailable: 'This item is currently not available for purchase.',
      items: 'Items',
      lastName: 'Last Name',
      leaveNotes: 'Want to leave any notes for the chef?',
      letsDoThis: 'Let’s Do This',
      letsEat: `Let's eat`,
      loadMore: 'Load More',
      login: 'Login',
      loginRequiredEvents: 'is required to view events',
      loginWithFacebook: 'Login with Facebook',
      loginWithGoogle: 'Login with Google',
      logout: 'Logout',
      memo: 'Memo',
      message: 'Message',
      moreEventsAtLocation: 'More Events at this Location',
      myBusinesses: 'My Businesses',
      myEvents: 'My Events',
      newToGoodfynd: 'New to Goodfynd',
      nextEvent: 'Next Event',
      newPassword: 'Enter your new password below',
      noEvents: 'No events upcoming',
      noPickupTimes: `Sorry, there are no more pickup times remaining ${emojis.sad}.`,
      notForSale: 'Not for Sale',
      open: 'Open',
      openToday: 'Open Today',
      openTomorrow: 'Open Tomorrow',
      optional: 'Optional',
      or: 'or',
      orderHistory: 'Order History',
      orderNow: 'Order Now',
      orders: 'Orders',
      password: 'Password',
      passwordMinLength:
        'A secure password must be at least 8 characters long.',
      paymentAccepted: 'Payment Accepted',
      paymentAgreement:
        'I agree to pay the above total amount according to my card issuer agreement.',
      payWithQr: 'Pay with QR Code',
      personalInfo: 'Personal Info',
      requestDelete: 'Request Account Deletion',
      deleteProfile: 'Delete Profile',
      deleteAccount: 'Delete Account',
      phone: 'Phone',
      pickupTimeExpired: 'Pickup time slot has been removed',
      placeOrder: 'Place Order',
      preferences: 'Preferences',
      privacyPolicy: 'Privacy\u00A0Policy',
      proceedToCheckout: 'Proceed to Checkout',
      products: 'Products',
      profileUpdated: 'Profile Updated',
      requestAccountDeleted: 'Requested account deletion',
      promoApplied: 'applied!',
      reason: 'Reason',
      remove: 'Remove',
      removeItem: 'Remove Item',
      required: 'Required',
      requestPasswordReset: 'Request Password Reset',
      resendCode: 'Resend Code',
      reset: 'Reset',
      resultsNear: 'Showing results near',
      retry: 'Retry',
      selectPaymentMethod: 'Please select valid payment method',
      send: 'Send',
      sendEmail: 'Send Email',
      sendMessage: 'Send Message',
      settings: 'Settings',
      share: 'Share',
      showingMenu: 'Showing menu for',
      signIn: 'Sign In',
      signMeUp: 'Sign Me Up',
      signUp: 'Sign Up',
      signUpSuccessMessage:
        'Congratulations, you’ve added your truck on Goodfynd!\nWe’ll reach out to finish your account setup.',
      signUpSuccessMessageConsumer:
        'Congratulations, you’ve added your account on Goodfynd!\nWe’ll reach out to finish your account setup.',
      signUpWithFacebook: 'Signup with Facebook',
      signUpWithGoogle: 'Signup with Google',
      specialRequests: 'Special Requests',
      startEarning: 'Start Earning More Now',
      startUsing: 'Start Using Goodfynd',
      submit: 'Submit',
      subtotal: 'Subtotal',
      taxesAndFees: 'Taxes & Fees',
      terms: 'Terms',
      thanks: 'Thanks You!',
      thisEventTrucks: `This Event's Trucks`,
      tip: 'Tip',
      today: 'Today',
      tomorrow: 'Tomorrow',
      total: 'Total',
      trucks: 'Trucks',
      tryAgain: 'Try Again',
      unFavorite: 'Unfavorite',
      update: 'Update',
      updatePickupTime: 'Update Pickup Time',
      username: 'Username',
      verify: 'Verify',
      vendors: 'Vendors',
      vendorsOpen: 'Vendors Open',
      viewAllEvents: 'View All Events',
      viewEvent: 'View Event',
      viewFullSchedule: 'View Full Schedule',
      viewMoreEvents: 'View More Events',
      viewReceipt: 'View Receipt',
      weEmailConfirmation: `We'll email you a confirmation and receipt`,
      welcomeBack: 'Welcome back',
      yesterday: 'Yesterday',
    },
    messages: {
      accountCreationSuccess: `Please check your email and verify your account before logging in.
          If you do not receive the verification email, please check your spam folder.`,
      allInOne:
        'Goodfynd’s simple to use all in one system will equip you to build a consistent brand and foodie experience. Are you ready to watch your sales skyrocket?',
      checkoutQR: 'Claim your order with this QR code',
      goFindFood: 'Go find yourself something good to eat!',
      passionIsFood:
        'Your passion is food. Keep doing what you do best, and we’ll send the fans, foodies, and events your way! Save time and money, all while growing your business.',
      resetPasswordSuccess:
        'Congratulations, you have successfully reset your password',
      simplifyGrowth:
        'Discover how to simplify your growth and join the 500+ truck owners who have already accelerated their revenue stream.',
      takeGuessworkOut:
        'You have enough on your plate. Take the guesswork out of finding the best catering for your next event.',
      verifyFailed: 'Sorry, verification failed. Please try again.',
      vendorLeadCaptured: `We saved your information and a sales representative will be in touch soon. Use the link below to continue setting up your business profile.`,
      verifyPhone:
        'We sent a special code to your mobile number. Please enter the code below to ensure that we can send you order updates.',
      verifyEmailSuccess:
        'Congratulations, your account is now verified! You may now login and order some dank food.',
    },
    misc: {
      and: 'and',
    },
    orders: {
      pickupTime: 'Estimated Pickup Time: :time',
      placed: `Placed :day at :time${unicode.centerDotPadded}:total`,
    },
    titles: {
      accessMoreCustomers: 'Access More Customers',
      addNewCard: 'Add New Card',
      addTip: 'Add a tip',
      bookATruck: 'Book a Food Truck for Your Event',
      changePickupOptions: 'Change Pickup Time and Location',
      checkout: 'Checkout: Confirm Your Info',
      checkoutConfirmation: 'Checkout: Confirmation',
      checkoutReview: 'Checkout: Review',
      checkoutThanks: 'Checkout: Thanks',
      choosePaymentMethod: 'Choose Payment Method',
      choosePickupOptions: 'First, Choose Pickup Time and Location',
      choosePickupTime: 'Choose Pickup Time',
      confirmationEmailSent: `We've sent a confirmation email to`,
      confirmInfo: 'Confirm Your Info',
      endToEnd: 'The end to end solution for mobile food businesses',
      eventDetails: 'Event Details',
      eventsNearby: 'Events Nearby',
      faq: 'Frequently asked questions',
      favoriteTrucks: 'Your Favorite Trucks',
      favoriteVendors: 'Your Favorite Vendors',
      featuredItem: 'Featured Item',
      folksLoveGoodfynd: 'Why folks love Goodfynd events',
      foodTrucksCatering: 'Food trucks, duh!',
      foodTruckSchedule: 'Food Truck Schedule',
      forFoodies: 'For Foodies',
      forTruckOwners: 'For Truck Owners',
      fromTheBlog: 'From the Blog',
      gatherSafely: 'Gathering safely',
      getListed: 'Get Your Truck Listed',
      howSimple: 'How simple is it?',
      hungry: 'Hungry?',
      hungrySubTitle: 'Find and order from food trucks near you!',
      joinGoodfyndCommunity: 'Join The Goodfynd Community',
      joinTheTeam: 'Join the Dream Team',
      logout: 'Logout',
      noFussCatering: 'No Fuss Catering',
      noUpcomingEvents: 'No Upcoming Events',
      openPositions: 'Open Positions',
      orderCanceled: `Your order has been canceled ${emojis.sad}`,
      orderNextMeal: 'Order Your Next Meal',
      orderReceived: 'Your order has been received!',
      orderRefunded: 'Your order has been refunded',
      ourBenefits: 'Our Benefits',
      ourValues: 'Our Values',
      payment: 'Payment',
      productDetails: 'Product Details',
      resetPassword: 'Reset Password',
      savedCards: 'Saved Cards',
      signIn: 'Welcome Back',
      signUp: 'Create an Account',
      simplifyCatering: 'Simplify catering with one click',
      siteMaintenance: 'Site Under Maintenance',
      takeControl: 'Take control of your business',
      trucksNearby: 'Trucks Nearby',
      scheduleDetails: 'Schedule Details',
      vendorDetails: 'Vendor Details',
      verifyPhone: 'Verify Mobile Number',
      weeklyFoodFight: 'Weekly Food Fight',
      welcome: 'Welcome',
      whatFolksSay: 'What Folks Say About Us',
      yourBag: 'Your Bag',
    },
    validations: {
      acceptTerms: 'You must accept terms to continue.',
      addressRequired: 'An address is required',
      companyRequired: 'A company name is required',
      emailRequired: 'An email address is required.',
      firstNameRequired: 'First name is required',
      invalidEmailFormat: `A valid email can only contain latin letters, numbers, '@' and '.'.`,
      lastNameRequired: 'Last name required',
      mobileInvalid: 'Please provide a valid mobile phone number.',
      passwordLength: 'Password must be at least 8 characters long.',
      passwordRequired: 'A password is required.',
      phoneInvalid: 'Please provide a valid phone number.',
      postalCodeRequired: 'A postal code is required',
      usernameRequired: 'An email or username is required.',
      zipInvalid: 'Please provide a valid postal code.',
      regex: {
        phone: new RegExp(
          /([+]?\d{1,2}[\s]?)?(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}/
        ),
      },
    },
  },
});

export default strings;
