import {
  addBusinessDays,
  addDays,
  addHours,
  addMinutes,
  areIntervalsOverlapping,
  differenceInDays,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getDate,
  getHours,
  getMinutes,
  isSameDay,
  isToday,
  isTomorrow,
  isYesterday,
  setDate,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

const dateVendor: {
  addBusinessDays: (date: Date, days: number) => Date;
  addDays: (date: Date, days: number) => Date;
  addHours: (date: Date, hours: number) => Date;
  addMinutes: (date: Date, minutes: number) => Date;
  differenceInDays: (
    dateLeft: Date | number,
    dateRight: Date | number
  ) => number;
  differenceInMinutes: (
    dateLeft: Date | number,
    dateRight: Date | number
  ) => number;
  endOfDay: (date: Date) => Date;
  endOfMonth: (date: Date) => Date;
  endOfWeek: (date: Date) => Date;
  endOfYear: (date: Date) => Date;
  format: (date: Date, format: string) => string;
  getDate: (date: Date) => number;
  getHours: (date: Date) => number;
  getMinutes: (date: Date) => number;
  isSameDay: (dateLeft: Date, dateRight: Date) => boolean;
  isToday: (date: Date) => boolean;
  isTomorrow: (date: Date) => boolean;
  isYesterday: (date: Date) => boolean;
  overlaps: (left: Interval, right: Interval) => boolean;
  setDate: (date: Date, dayOfMonth: number) => Date;
  startOfDay: (date: Date) => Date;
  startOfMonth: (date: Date) => Date;
  startOfWeek: (date: Date) => Date;
  startOfYear: (date: Date) => Date;
} = {
  addBusinessDays,
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getDate,
  getHours,
  getMinutes,
  isSameDay,
  isToday,
  isTomorrow,
  isYesterday,
  overlaps: areIntervalsOverlapping,
  setDate,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
};

export default dateVendor;
