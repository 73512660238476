import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { CreditCardModel } from './credit-card';

/**
 * Order Request model.
 */
export const CustomerModel = types.model('Customer', {
  card: types.maybeNull(CreditCardModel),
  creditCards: types.array(CreditCardModel),
  email: types.string,
  firstName: types.string,
  fullName: types.string,
  hasPhone: types.optional(types.boolean, false),
  id: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  phone: types.string,
  postalCode: types.string,
});

type CustomerType = Instance<typeof CustomerModel>;
export interface Customer extends CustomerType {}
type CustomerSnapshotType = SnapshotOut<typeof CustomerModel>;
export interface CustomerSnapshot extends CustomerSnapshotType {}
export const createOrderDefaultModel = () => types.maybe(CustomerModel);
