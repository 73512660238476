import React, { useState } from 'react';
import { Alert, Button, Icon, Loading } from '@goodfynd/react-web.lib.ui';

import strings from '../../config/strings';
import { StyledSearchTextField } from '../../context/search-context/SearchInputs/styles';
import { useStores } from '../../stores';

import { StyledPromoForm, StyledPromoInput } from './styles';
import type { PromoFormProps } from './types';

export default function PromoForm({ appliedPromo, ...props }: PromoFormProps) {
  const [errors, setErrors] = useState<string[]>([]);
  const [promo, setPromo] = useState('');
  const { orderStore } = useStores();

  const applyPromo = async (e: ClickButtonEvent) => {
    e.preventDefault();
    setErrors([]);

    try {
      await props.applyPromo(promo);
      setPromo('');
    } catch (error: any) {
      console.warn(error);

      setErrors(
        error
          ? [
              error.message,
              ...(error.errors || [])
                .filter((error: ResponseError) => error.code !== 'trace')
                .map((error: ResponseError) => error.message),
            ]
          : []
      );
    }
  };

  return (
    <StyledPromoForm {...props}>
      <StyledPromoInput>
        <StyledSearchTextField
          error={errors.length > 0}
          label={strings.labels.enterPromoCode}
          onChange={(e) => setPromo(e.target.value)}
          value={promo}
          css={{
            '&.MuiTextField-root': {
              marginRight: 8,
            },
          }}
        />

        <Icon name="discount" size={32} />
      </StyledPromoInput>

      {!!promo && (
        <Button
          disabled={!promo || orderStore.busy}
          fullWidth
          onClick={applyPromo}
          style={{ marginTop: 16 }}
          type="submit"
        >
          {orderStore.busy ? <Loading type="line" /> : strings.labels.apply}
        </Button>
      )}

      {errors.length > 0 && (
        <Alert status="error" style={{ marginTop: 16 }}>
          {errors}
        </Alert>
      )}

      {!!appliedPromo && (
        <Alert
          status="success"
          style={{ marginTop: 16 }}
        >{`${appliedPromo} ${strings.labels.promoApplied}`}</Alert>
      )}
    </StyledPromoForm>
  );
}
