import config from '../config';
import { useStores } from '../stores';
import {
  BasicCustomer,
  CreditCard,
  RemoveItemRequest,
  UpdatePickupRequest,
} from '../types/orders';
import { useOrderApi } from './api';

export const useOrderActions = () => {
  const orderApi = useOrderApi();
  const {
    orderStore: {
      productId: orderStoreProductId,
      request: orderStoreRequest,
      removeCard: orderStoreRemoveCard,
      resetOrder: orderStoreResetOrder,
      setBusy: orderStoreSetBusy,
      setCustomer: orderStoreSetCustomer,
      updated: orderStoreUpdated,
    },
  } = useStores();
  return {
    addItem: async (quantity: number, requests: string, name: string) => {
      try {
        if (!orderStoreProductId || !orderStoreRequest) {
          return Promise.reject();
        }

        orderStoreSetBusy();

        const cart = await orderApi.addItem(
          {
            ...orderStoreRequest,
            productId: orderStoreRequest.productId || orderStoreProductId,
            quantity,
            requests,
          },
          orderStoreRequest.orderId
        );

        orderStoreUpdated();

        // Track add to cart
        const order = cart.list.find((order) =>
          order.items.find((item) => item.id === orderStoreProductId)
        );

        return cart;
      } catch (error: any) {
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
    applyPromo: async (code: string) => {
      try {
        orderStoreSetBusy();
        const cart = await orderApi.applyPromo(code);
        return cart;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
    removeItem: async (productId: string, request: RemoveItemRequest) => {
      try {
        const cart = await orderApi.removeItem(productId, request);
        return cart;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      }
    },
    payCredit: async (payment: Partial<CreditCard>) => {
      try {
        orderStoreSetBusy();
        console.debug(payment);
        const { orders } = await orderApi.payCredit(payment);

        orderStoreResetOrder();
        return orders;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
    removeCreditCard: async (card: CreditCard) => {
      try {
        const { success } = await orderApi.removeCreditCard(card);
        if (success) {
          card.id && orderStoreRemoveCard(card.id);
        }

        return success;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      }
    },
    updateCustomer: async (request: BasicCustomer) => {
      try {
        orderStoreSetBusy();
        const data = await orderApi.updateCustomer(request);
        orderStoreSetCustomer(data.customer);
        return data;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
    updatePickupTime: async (request: UpdatePickupRequest) => {
      try {
        orderStoreSetBusy();
        const cart = await orderApi.updatePickupTime(request);
        return cart;
      } catch (error: any) {
        console.warn(error);
        return Promise.reject(error);
      } finally {
        orderStoreSetBusy(false);
      }
    },
  };
};
