import * as queryUtil from '../../utils/query-util';
import endpoints from './endpoints';

import type {
  ButterCmsContentSearchResponse,
  ButterCmsPageType,
} from './types';

export default class CmsApi {
  async getCollectionData(
    slug: string,
    fieldName: string,
    fieldValue: string
  ): Promise<any> {
    try {
      const response = await fetch(
        `${endpoints.cms.collection}?${queryUtil.toString({
          slug,
          [`fields.${fieldName.replace(/ /g, '_').toLowerCase()}`]: fieldValue,
        })}`
      );
      const text = await response.text();
      const {
        data: { data },
      } = text && JSON.parse(text);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async getComponentData(path: string): Promise<any> {
    try {
      const response = await fetch(
        `${endpoints.cms.link}?${queryUtil.toString({
          path,
        })}`
      );
      const text = await response.text();
      const {
        data: { data },
      } = text && JSON.parse(text);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async getPageData(
    slug: string,
    page_type: ButterCmsPageType = '*'
  ): Promise<any> {
    try {
      const response = await fetch(
        `${endpoints.cms.retrieve}?${queryUtil.toString({
          page_type,
          slug,
        })}`
      );
      const text = await response.text();
      const {
        data: { data },
      } = text && JSON.parse(text);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async search(
    query = '',
    page_type: ButterCmsPageType = '*'
  ): Promise<ButterCmsContentSearchResponse> {
    try {
      const response = await fetch(
        `${endpoints.cms.search}?${queryUtil.toString({
          page_type,
          query,
        })}`
      );
      const text = await response.text();
      const { data } = text && JSON.parse(text);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }
}
