import React, { useState } from 'react';
import { Text } from '@goodfynd/react-web.lib.ui';
import { styled } from '@goodfynd/react-web.theme';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { StyledNavLink } from './styles';
import type { NavLink } from './types';
import { NavDropdown } from './';

/* This one is an example of a Dropdown that is generated purely from data, using no children */

const StyledLi = styled('li', {
  display: 'block',
  color: 'inherit',
  '&:hover, &:active': {
    textDecoration: 'nnone',
  },
  '+ li': {
    marginLeft: 8,
  },
});

export const SimpleNav = ({
  href = '',
  label,
  links = [],
}: NavLink): JSX.Element => {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (href) {
      router.push(href);
      return;
    }

    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasSubLinks = links?.length > 0;
  const currentPath = router.asPath.split('?')[0];
  return (
    <StyledLi>
      <StyledNavLink
        aria-controls={hasSubLinks ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup={hasSubLinks}
        display="underline-none"
        highlight
        href={href}
        onClick={hasSubLinks ? handleClick : undefined}
      >
        <Text as="subhead3" inherit={currentPath === href} tag="span">
          {label}
        </Text>
      </StyledNavLink>

      {links?.length > 0 && (
        <NavDropdown
          anchorEl={anchorEl}
          handleClose={handleClose}
          items={links}
          open={open}
        />
      )}
    </StyledLi>
  );
};

export default observer(SimpleNav);
