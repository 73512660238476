import * as React from 'react';
import Menu from '@mui/material/Menu';

import type { NavLink } from '../layout/Nav/types';

import DropdownItem from './DropdownItem';
import type { DropdownListProps } from './types';

const renderItems = (items: NavLink[]) => {
  return items?.length ? (
    items.map((item) => <DropdownItem key={item.label} {...item} />)
  ) : (
    <span>There doesn&apos;t seem to be anything here....</span>
  );
};
/** This component serves as the container for the dropdown items */
export default function DropdownList({
  anchorEl,
  items,
  open,
  handleClose,
}: DropdownListProps) {
  React.useCallback(renderItems, [items]);

  return (
    <Menu
      anchorEl={anchorEl}
      id="basic-menu"
      onClose={handleClose}
      open={open}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {renderItems(items)}
    </Menu>
  );
}
