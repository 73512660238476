import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import * as stringUtil from '../../utils/string-util';

import type {
  GetLikedVendorsRequest,
  GetLikedVendorsResponse,
  ToggleLikeRequest,
} from '../../types/likes';
export const useLikeApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async getLikedVendors(
        request: GetLikedVendorsRequest
      ): Promise<GetLikedVendorsResponse> {
        try {
          return await api.get(endpoints.likes.likedVendors, {
            ...request,
          });
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async toggleLike(request: ToggleLikeRequest): Promise<void> {
        if (request.isLiked) {
          await api.delete(
            stringUtil.replaceAll(endpoints.likes.delete, {
              ':entityId': request.entityId,
              ':entityType': request.entityType,
            })
          );
        } else {
          await api.post(endpoints.likes.base, {
            entityId: request.entityId,
            entityType: request.entityType,
          });
        }
      },
    };
  }, [api]);
};
